import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FormTicket from './Ticket/FormTicket';
import ItemTicket from './Ticket/ItemTicket';
import { LoadSpiner } from '../../routers/LoadSpiner';
import PlanNotFound from './Generics/PlanNotFound';
import { removeStorageItemWithUrl, getStorageItems } from '../../helpers/localState';
import { updateGoogleAnalitics } from '../../helpers/tracking';
import { startUpdateTracking } from '../../actions/auth';
import { startUpdatePrecase } from '../../actions/flow';
import { startGetCoverage, startGetWarrantyTicketPlan } from '../../actions/plan';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { Contenedor } from '../Contenedor';
import ErrorPage from '../../routers/ErrorPage';

function WarrantyTicketFinder() {
  try {
    //Objeto para agilizar pruebas en test --> MockupData.js
    //se inicializan variables a utilizar en el componente
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading: GlobalLoading } = useSelector((state) => state.ui);
    const { insuredMaterial: ticketStore } = useSelector((state) => state.plan);
    const [checking, setChecking] = useState(false);
    const { coverage, activeService } = getStorageItems();
    const [idTicket, setIdTicket] = useState(null);
    const { sponsor, ruta } = !activeService ? { sponsor: 0, ruta: '' } : activeService;

    // Se ejecuta la actualización de Google Analitics y ingreso de pagina
    useEffect(() => {
      updateGoogleAnalitics();
      dispatch(startUpdateTracking({ request: 'ingresando a la pagina...' }));
    }, []);
    //Cuando cambia las dependencias se valida si contiene la información para moverlo a la pantalla de covertura
    useEffect(() => {
      setChecking(coverage?.length > 0 ? true : false);
      if (checking) {
        navigate('/view/auth/CoverageService');
      }
    }, [coverage, checking]);

    //Se ejecuta la petición de datos para obtener las boletas de garantia
    const handleSubmit = (idTicketSend) => {
      if (idTicketSend === idTicket) {
        return;
      }
      console.log(ticketStore, activeService);
      dispatch(startGetWarrantyTicketPlan(idTicketSend, sponsor));
      setTimeout(() => {
        setIdTicket(idTicketSend);
      }, 300);
    };
    //Se ejecuta la petición de datos para obtener el plan de cobertura
    const handleTicketSelect = (idPlanMateria, htmlMateriaAsegurada) => {
      const infoProduct = `${htmlMateriaAsegurada.producto} - ${htmlMateriaAsegurada.marca}`;
      const selectMaterial = {
        idSponsor: Number(activeService?.sponsor),
        idTipoServicio: Number(activeService?.idTipoServicio),
        idPlanMateria: Number(idPlanMateria),
      };
      dispatch(startGetCoverage(selectMaterial, infoProduct, ''));
      dispatch(startUpdatePrecase());
    };
    //Se limpia el localStorage para que no se guarde la información de la boleta no confirmada
    const handleReturn = () => {
      removeStorageItemWithUrl('TypeService');
      navigate('/view/auth/TypeService');
    };
    const component = (
      <>
        <header className="header-tertiary">
          <BiLeftArrowAlt className="icon icon-link" onClick={handleReturn} />
          <div className="header-tertiary__logo-box">
            <AiOutlineFileSearch size={70} />
          </div>
          <h1 className="title__tertiary-title">
            Busqueda por <br /> N° de Boleta
          </h1>
        </header>
        <FormTicket handleSubmit={handleSubmit} />
        {!GlobalLoading ? (
          ticketStore?.length > 0 ? (
            <ItemTicket ticket={ticketStore} ruta={ruta} handleTicketSelect={handleTicketSelect} />
          ) : (
            idTicket && (
              <PlanNotFound
                title={'Resultado'}
                question={
                  'Por algun motivo no encontramos la boleta: ' +
                  idTicket +
                  ' No te preocupes! aun puedes ingresar tu solicitud'
                }
                button={'Solicitar Garantia'}
              />
            )
          )
        ) : (
          <div className="spiner-box">
            <h3 className="info-spinner-ticket">Buscando boletas...</h3>
            <div className="spinner-position-ticket">
              <LoadSpiner />
            </div>
          </div>
        )}
      </>
    );
    //Renderizado del componente
    return <Contenedor component={component} />;
  } catch (error) {
    return <ErrorPage error={error} />;
  }
}

export default WarrantyTicketFinder;
