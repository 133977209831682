import publicIp from 'public-ip';
import ReactGA from 'react-ga4';
import { detect } from 'detect-browser';
import { getConfigData } from './config';
import { UseCookies } from './../Hooks/UseCookies';
import { sessionState } from './../types/sessionState';
import { webClientBase } from './../Services/webClientBase';
//import { tipoDocumento } from '../types/user';

//actualización de google analitics de navegación
export const updateGoogleAnalitics = () => {
  ReactGA.initialize(getConfigData(process.env.NODE_ENV).googleAnalytics);
  nobackbutton();
  //ReactGA.send({ hitType: "visitando la pagina", page: window.location.pathname });
};
/*// Envio de eventos personalizado
ReactGA.event({
  category: "your category",
  action: "your action",
  label: "your label", // optional
  value: 99, // optional, must be a number
  nonInteraction: true, // optional, true/false
  transport: "xhr", // optional, beacon/xhr/image
}); */
//obtener la informacion del navegador ip flag y setear el guid con el request
export const getBrowserInfo = async ({ guid, request }) => {
  try {
    const browser = detect();
    const ip = await publicIp.v4();
    const { originStr } = getParams();
    const obj = {
      guid,
      flag: window.location.href,
      request,
      ip: ip,
      browser: 'browser: ' + browser.name + '| version: ' + browser.version + '| os: ' + browser.os,
      origin: originStr,
    };
    return obj;
  } catch (error) {
    const browser = { name: 'Undentify', version: '9.0', os: 'Linux' };
    const ip = '255.255.255.0';
    const { originStr } = getParams();
    const obj = {
      guid,
      flag: window.location.href,
      request,
      ip: ip,
      browser: 'browser: ' + browser.name + '| version: ' + browser.version + '| os: ' + browser.os,
      origin: originStr,
    };
    return obj;
  }
};
//Obtencion de url de la pagina solo parametros validos para la configuracion por detras
export const getParams = () => {
  const coockie = UseCookies({ field: 'config', action: sessionState.GET_COOKIE });
  const url = window.location.href;
  window.searchParams = new URLSearchParams(url);
  let params = [];
  //Extraigo los parametros de la url
  for (const p of window.searchParams) {
    params.push({ name: p[0], value: p[1] });
  }
  if (!params[0] || !params[1] || !params[2]) {
    const queryString = {
      sponsor: Number(coockie?.sponsor) || 0,
      flujo: Number(coockie?.flujo) || 1,
      pais: Number(coockie?.pais.toString().replace('#n', '')) || 1,
      origin: Number(coockie?.origin) || 1,
      data: coockie?.data?.toString().replace('#n', '') || ''
    };
    const originStr = queryString?.origin?.toString();
    return { ...queryString, originStr };
  } else {
    const queryString = {
      sponsor: params[0].value,
      flujo: params[1].value,
      pais: params[2].value.toString().replace('#n', ''),
      origin: params[3]?.value || 1,
      data: params[4]?.value?.toString().replace('#n', '') || ''
    };
    const originStr = queryString?.origin?.toString();
    return { ...queryString, originStr,idDocument:1 };
  }
};
//imprimir la informacion del browser para debuging
export const printBrowserInfo = async () => {
  await getBrowserInfo({ guid: '134564', request: 'test' });
};
//impide la navegacion atras desde el browser
const nobackbutton = () => {
  window.location.hash = 'n';
  window.location.hash = 'n';
  window.onhashchange = function () {
    window.location.hash = 'n';
  };
};

export const redirectSponsor = () => {
  const pathSponsor = window.location.pathname.replace(/[^a-zA-Z]/g, '');
  const host = window.location.host;
  webClientBase.auth
    .setSponsorOrigin(pathSponsor)
    .then(async (session) => {
      const { data } = await JSON.parse(session);
      window.location.href = `https://${host}${data.urlSponsor}`;
    })
    .catch(async () => {
      window.location.href = `https://${host}/view/HomePage`;
    });
};
