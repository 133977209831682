import Cookies from "universal-cookie";
import { sessionState } from "../types/sessionState";

export const UseCookies = ({ field, data = "", path = "", action, age = 3600 }) => {
	const cookies = new Cookies();
	switch (action) {
		case sessionState.GET_COOKIE:
			return cookies.get(field);
		case sessionState.SET_COOKIE:
			cookies.set(field, data, { path: path, maxAge: age });
			break;
		case sessionState.REMOVE_COOKIE:
			cookies.remove(field, { path: path });
			break;
		case sessionState.GET_ALL_COOKIE:
			return cookies.getAll({ doNotParse: false });
		default:
			break;
	}
};
