export const plan = {
	idContratoTipo: 0,
	idFlujoTipoServicio: 0,
	idTipoServicio: 0,
	idSponsor: 0,
};

export const servicios = {
	idTipoServicio: "",
	nombre: "",
	idContratoTipo: "",
	idFlujoTipoServicio: "",
	ruta: "",
	esAbsent: "",
};

export const asistencia = {
	idTipoServicio: 0,
	idSponsor: 0,
	nroDocumento: "",
	nombreCompleto: "",
	direccion: "",
	numeroDir: "",
	comuna: "",
	aniovehiculo: "",
	marcaVehiculo: "",
	modeloVehiculo: "",
	patente: "",
	numeromotor: "",
	numerochassis: "",
	viajeOrigen: "",
	viajeDestino: "",
};

export const garantia = {
	idTipoServicio: 0,
	idSponsor: 0,
	nroDocumento: "",
	boleta: "",
	nombreMarca: "",
	producto: "",
	nserie: "",
	fechacompra: "",
	lugarcompra: "",
	valorproducto: "",
	modelo: "",
	file: "",
};

export const preCaso = {
	idTipoServicio: 0,
	idPlanMateria: 0,
	idCoberturaServicio: 0,
	script: "",
	emailContacto: "",
	fonoContacto: "",
	latitud: 0,
	longitud: 0,
	latitudDestino: 0,
	longitudDestino: 0,
	direccion: "",
	comuna: "",
	comunaDestino: "",
	direccionDestino: "",
	programado: false,
	fechaProgramada: "",
	gpsEtaMin: 0,
	gpsDistanciaKm: 0,
	nombres: "",
	apellidos: "",
	flujo: 0,
	requierePago: false,
	pendientePago: false,
};
