import { auth } from "./properties/auth";
import { plan } from "./properties/plan";
import { getConfigData } from "./../helpers/config";
const Uri = getConfigData(process.env.NODE_ENV).api;
const apiParams = {
	apiServiceUri: Uri,
	myHeaders: new Headers(),
};
apiParams.myHeaders.append("Content-Type", "application/json");
export const webClientBase = {
	auth: auth(apiParams),
	plan: plan(apiParams),
};
