import ErrorPage from "../routers/ErrorPage";

export const Contenedor = ({ component }) => {
	try {
		return (
			<div className="contenedor-maxw">
				<section className="big-box-white">{component}</section>
			</div>
		);
	} catch (error) {
		return <ErrorPage error={error} />;
	}
};
