import { sessionState } from "./../types/sessionState";

export const authReducer = (state = {}, action) => {
	switch (action.type) {
		case sessionState.sessionGUID:
			return {
				...state,
				session: action.payload.session,
			};
		case sessionState.LOGIN:
			return {
				...state,
				data: action.payload.data,
			};
		case sessionState.RESTORE_SESSION:
			return {
				...state,
				lastSession: action.payload.lastSession,
			};
		case sessionState.setTypeDocument:
			return {
				...state,
				typeDocument: action.payload.typeDocument,
			};
		case sessionState.LOGOUT:
			return {};
		default:
			return state;
	}
};
