import { sessionState } from "./../types/sessionState";
import { UseCookies } from "./../Hooks/UseCookies";

// Metodos encargado en controlar la interfaz de usuario a nivel de diseño y configuración de la aplicación.
// Se asigna el mensaje de error al store el cual sera mostrado en la interfaz de usuario
export const uiSetError = (msgError) => {
	return {
		type: sessionState.uiSetError,
		payload: msgError,
	};
};
// Quita el mensaje de error del store
export const uiRemoveError = () => {
	return {
		type: sessionState.uiRemoveError,
	};
};
// Marca el inicio de un flujo de redux en el store
export const startLoading = () => ({
	type: sessionState.uiStartLoading,
});
// Marca el fin de un flujo de redux en el store
export const finishLoading = () => ({
	type: sessionState.uiFinishLoading,
});
// Asinga la configuracion de sponsor flujo y pais en el store
export const uiSetPageConfig = (config) => {
	console.log(config);
	setAccessCoockie({ config: config });
	return {
		type: sessionState.uiSetPageConfig,
		payload: config,
	};
};
// Asigna los estilos de la pagina al store
export const uiSetPageStyle = (pageStyle) => ({
	type: sessionState.uiSetPageStyle,
	payload: pageStyle,
});
// Reinicia a los valores por defecto el stilo al store
export const uiResetPageStyle = () => ({
	type: sessionState.uiResetPageStyle,
});


export const uiSetDocument = (config) => ({

	type: sessionState.uiSetDocument,
	payload:config,
});

// Metodos de gestion de informacion persistente en la session activa.
// asigna y reasigna el estado de la sesion dentro del store
const setAccessCoockie = ({ config }) => {
	if (config) {
		UseCookies({
			field: "config",
			path: "/",
			action: sessionState.REMOVE_COOKIE,
		});
	}
	const noExists = !UseCookies({
		field: "config",
		data: config,
		path: "/",
		action: sessionState.GET_COOKIE,
	});
	if (noExists) {
		UseCookies({
			field: "config",
			data: config,
			path: "/",
			age: 86400,
			action: sessionState.SET_COOKIE,
		});
	}
};
