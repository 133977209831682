import { apiPostPromise } from './../../helpers/callApi';
//Metodos de llamado a la API para el servicio de autenticacion
export const auth = ({ apiServiceUri, myHeaders }) => {
  return {
    setPageStyle: async function (sponsor) {
      const raw = JSON.stringify({ id: sponsor });
      return await apiPostPromise(`${apiServiceUri}Auth/obtener-sponsor/`, myHeaders, raw, 'ok');
    },
    setSponsorOrigin: async function (sponsor) {
      const raw = JSON.stringify({ id: sponsor });
      return await apiPostPromise(`${apiServiceUri}Auth/obtener-sitio-sponsor/`, myHeaders, raw, 'ok');
    },
    login: async function (user) {
      const raw = JSON.stringify(user);
      return await apiPostPromise(`${apiServiceUri}Auth/login/`, myHeaders, raw, 'ok');
    },
    register: async function (user) {
      const raw = JSON.stringify(user);
      return await apiPostPromise(
        `${apiServiceUri}Auth/registrar-user/`,
        myHeaders,
        raw,
        'usuario registrado exitosamente'
      );
    },
    sendSMS: async function (dni) {
      const raw = JSON.stringify(dni);
      return await apiPostPromise(
        `${apiServiceUri}Auth/pedir-cambio-clave/`,
        myHeaders,
        raw,
        'se ha enviado un sms al teléfono'
      );
    },
    sendWSP: async function (dni) {
      const raw = JSON.stringify(dni);
      return await apiPostPromise(
        `${apiServiceUri}Auth/pedir-cambio-clave-wsp/`,
        myHeaders,
        raw,
        'se ha enviado un WhatsApp al teléfono'
      );
    },
    sendVerifingCode: async function (dni, code) {
      const dataBody = {
        rut: dni,
        idSponsor: '',
        data: code,
      };
      const raw = JSON.stringify(dataBody);
      return await apiPostPromise(
        `${apiServiceUri}Auth/recibe-clave`,
        myHeaders,
        raw,
        'código validado exitosamente'
      );
    },
    sendNewPassword: async function (password, authToken) {
      const raw = JSON.stringify(password);
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      return await apiPostPromise(
        `${apiServiceUri}auth/restablecer-clave/`,
        myHeaders,
        raw,
        'se ha actualizado la clave del usuario'
      );
    },
    addNewTracking: async function (traking) {
      const raw = JSON.stringify(traking);
      return await apiPostPromise(`${apiServiceUri}auth/insertar-seguimiento/`, myHeaders, raw, 'ok');
    },
    updateTracking: async function (traking) {
      const raw = JSON.stringify(traking);
      return await apiPostPromise(`${apiServiceUri}auth/actualiza-seguimiento/`, myHeaders, raw, 'ok');
    },
    registerGeneric: async function (generic) {
      const raw = JSON.stringify(generic);
      return await apiPostPromise(`${apiServiceUri}auth/registrar-generic/`, myHeaders, raw, 'ok');
    },
    getTypesDocuments: async function (id) {
      return await apiPostPromise(`${apiServiceUri}auth/obtener-tipos-documentos/${id}`, myHeaders, null, 'ok');
    },
  };
};
