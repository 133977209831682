import { BiErrorAlt } from "react-icons/bi";

const nobackbutton = () => {
  window.location.hash = 'n';
  window.location.hash = 'n';
  window.onhashchange = function () {
    window.location.hash = 'n';
  };
};

export const HomePage = () => {
    nobackbutton();
    setTimeout(() => {
		window.location.replace("https://www.google.com");
	}, 5000);

  return (
        <div className="contenedor-maxw">
			<section className="big-box-white bor-rad20">
				<h1 className="title__tertiary-title mb20">¡ESTA PÁGINA HA CADUCADO!</h1>
                
                <div className="header-tertiary__logo-box">
					<BiErrorAlt size={140} />
				</div>
                
				<div className="box-center mb40">
					<h2 className="title__form-subtitle mar-0">Contáctate nuevamente por los canales de atención disponibles.</h2>
                </div>
                <div className="box-center mb40">
					<h3 className="title__form-subtitle mar-0">La pagina actual se cerrara en 5 segundos...</h3>
				</div>
			</section>
		</div>
  )
}
