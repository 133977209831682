import React from "react";

const HeadInfo = ({ title, activeService }) => {
	//Renderiza el componente
	return (
		<div className="header-quaternary">
			{activeService?.ruta.length >= 10 && (
				<div
					className="header-quaternary__img material-icons icon__blue svg-icom-custom-coverage"
					dangerouslySetInnerHTML={{ __html: activeService?.ruta.toString() }}
				/>
			)}
			<h6 className="header-quaternary__text">{title.replace(/undefined/g, "")}</h6>
		</div>
	);
};

export default React.memo(HeadInfo);
