import { UseCookies } from '../Hooks/UseCookies';
import { webClientBase } from './../Services/webClientBase';
import { sessionState } from './../types/sessionState';

import Swal from 'sweetalert2';
import { startLoading, finishLoading } from './ui';
import { startUpdateTracking } from './auth';
import { preCaso } from './../types/plan';
import { getStorageItems, removeCoockie, removeStorage, setStorage } from '../helpers/localState';
import { getStorageItem } from './../helpers/localState';
import { personalInfo } from './../types/user';

// Metodos de gestion de informacion persistente en la session activa.
export const startGetServiceLocation = (idServicio) => {
  return (dispatch, getState) => {
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .getServiceLocation(idServicio, tokenAuth)
      .then(async (session) => {
        const { data: ubicationState } = await JSON.parse(session);
        const { respuesta: ubication } = ubicationState;
        setStorage('ubicationState', ubication);
        dispatch(setUbicationFlow(ubication));
        dispatch(
          startUpdateTracking({
            request: `obtención de ubicación: correcto, type: success`,
          })
        );

        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          Swal.fire('UBICACIÓN', error.message, 'info');
          dispatch(
            startUpdateTracking({
              request: `obtención de ubicación: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};

export const startGetScript = (idCoberturaServicio) => {
  return (dispatch, getState) => {
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .getScript(idCoberturaServicio, tokenAuth)
      .then(async (session) => {
        const { data: scriptState } = await JSON.parse(session);
        setStorage('script', scriptState);
        dispatch(setScript(scriptState));
        dispatch(
          startUpdateTracking({
            request: `obtención de script: correcto, type: success`,
          })
        );

        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          Swal.fire('ATENCIÓN', error.message, 'info');
          dispatch(
            startUpdateTracking({
              request: `obtención de script: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};

//Actualización del precaso en la session activa.
export const startUpdatePrecase = () => {
  return (dispatch) => {
    const precase = createPrecase();
    const authToken = UseCookies({
      field: 'accessToken',
      action: sessionState.GET_COOKIE,
    });
    dispatch(setActivePrecase(precase));
    webClientBase.plan
      .updateCase(precase, authToken)
      .then(async () => {})
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        }
      });
  };
};

//Obtiene la informacion del usuario activo
export const startGetUpdatePersonalInfo = (persona = personalInfo) => {
  return (dispatch, getState) => {
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .sendGetPersonal(persona, tokenAuth)
      .then(async (session) => {
        const { data: personState } = await JSON.parse(session);
        const person = personState[0];
        setStorage('personalInfo', person);
        dispatch(setPersonFlow(person));
        dispatch(
          startUpdateTracking({
            request: `obtención de cliente: correcto, type: success`,
          })
        );

        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          Swal.fire('Datos Cliente', error.message, 'info');
          dispatch(
            startUpdateTracking({
              request: `obtención de cliente: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};

//Inicializa el proceso de creacion de caso en la session activa. Obtiene el numero de caso
export const setCreateCase = () => {
  return (dispatch) => {
    dispatch(startLoading());
    Swal.fire({
      title: 'Solicitando el servicio...',
      text: 'Espere porfavor',
      allowOutsideClick: false,
      timer: 10000,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const authToken = UseCookies({
      field: 'accessToken',
      action: sessionState.GET_COOKIE,
    });
    webClientBase.plan
      .createCase(authToken)
      .then(async (session) => {
        const { data } = await JSON.parse(session);
        const { url, idCaso } = data;
        if (idCaso) {
          dispatch(startUploadFile(url, idCaso, authToken));
        }
        dispatch(finishLoading());
      })
      .catch((error) => {
        Swal.close();
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        }
        dispatch(
          startUpdateTracking({
            request: `creacion de caso moksys: ${error.message}, type: error`,
          })
        );
        dispatch(finishLoading());
      });
  };
};

//Una vez creado el caso valida si tiene imagens y las sube al servidor asociado al codigo del caso
const startUploadFile = (url, idCaso, authToken) => {
  return async (dispatch) => {
    try {
      const images = getStorageItem('photos');
      const ticket = getStorageItem('ticket');
      if (!images && !ticket[0].file) {
        dispatch(setFinishCase(url, idCaso));
        return true;
      }
      let files = [];
      if (images?.length > 0) {
        files = images.map((image) => {
          return {
            archivo: image.data_url,
            nombreArchivo: image.title.replace('idCaso', idCaso).replace('undefined', 'jpg'),
            idCaso: Number(idCaso),
          };
        });
      }
      if (ticket?.file.length > 10) {
        files.push({
          archivo: ticket.file,
          nombreArchivo: ticket.name.replace('idCaso', idCaso).replace('undefined', 'jpg'),
          idCaso: Number(idCaso),
        });
      }
      if (files?.length < 1) {
        dispatch(setFinishCase(url, idCaso));
        return true;
      }
      const upload = await files.map((file) => {
        webClientBase.plan
          .loadFile(file, authToken)
          .then(async (session) => {
            if (session.status === 200) {
              return true;
            }
          })
          .catch(() => {
            return true;
          });
        return true;
      });
      if (upload) {
        dispatch(setFinishCase(url, idCaso));
      }
    } catch (e) {
      if (url && idCaso) {
        dispatch(setFinishCase(url, idCaso, e.message));
      }
    }
  };
};

//Finaliza el flujo de creacion de caso y notifica al usuario con un popup para mandarlo al sitio de tecking
const setFinishCase = (url, idCaso, message) => {
  return (dispatch) => {
    if (url) {
      setTimeout(() => {
        Swal.close();
        Swal.fire({
          title: `Servicio solicitado: ${idCaso}`,
          text: 'Se ha generado el servicio solicitado ahora sera redirigido al sitio de seguimiento',
          icon: 'success',
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            //limpiamos su sesion ya que finaliza el flujo 👌
            dispatch(
              startUpdateTracking({
                request: `creacion de caso moksys: Creado con exito, type: info`,
              })
            );
            removeCoockie('authToken');
            removeCoockie('accessToken');
            removeCoockie('sessionGuid');
            removeCoockie('dateService');
            removeCoockie('fields');
            removeStorage('all');
            //aqui ya lo sacamos de la pagina y lo redirigimos
            window.location.replace(url);
          }
        });
      }, 1500);
    }
    message &&
      dispatch(startUpdateTracking({ request: `creacion de caso moksys: ${message}, type: info` }));
  };
};

//Crea el objeto de precaso para enviar al servidor
const createPrecase = () => {
  let precase = preCaso;
  const {
    dateService,
    activeService,
    activeCoverage,
    activeCoverageService,
    ubication,
    point,
    answer,
    comment,
  } = getStorageItems();
  if (activeService) {
    precase = {
      ...precase,
      idTipoServicio: Number(activeService.idTipoServicio),
      flujo: Number(activeService.idFlujoTipoServicio),
    };
  }
  if (activeCoverage) {
    precase = {
      ...precase,
      idPlanMateria: Number(activeCoverage[0].planMateria),
      idCoberturaServicio: Number(activeCoverage[0].idCoberturaServicio),
    };
  }
  if (activeCoverageService) {
    precase = {
      ...precase,
      idCoberturaServicio: Number(activeCoverageService.idCoberturaServicio),
    };
  }
  if (point) {
    precase = {
      ...precase,
      latitud: point.origen.lat,
      longitud: point.origen.lng,
      latitudDestino: point.destino.lat,
      longitudDestino: point.destino.lng,
      gpsEtaMin: point.distance.distance,
      gpsDistanciaKm: point.distance.duration,
    };
  }
  if (ubication) {
    precase = {
      ...precase,
      direccion: ubication.originStreet,
      direccionDestino: ubication.destinyStreet,
      comuna: ubication.originCity,
      comunaDestino: ubication.destinyCity,
    };
  }
  if (!dateService) {
    precase = {
      ...precase,
      programado: false,
      fechaProgramada: new Date().toISOString(),
    };
  }
  if (dateService) {
    precase = {
      ...precase,
      programado: true,
      fechaProgramada: dateService,
    };
  }
  if (answer) {
    //recorrer answer y obtener la data concatenada en un string
    let data = '';
    // idQuestion: idScript, answer:
    answer.forEach((item) => {
      data += `${item.idQuestion},${item.answer};`;
    });
    precase = {
      ...precase,
      script: data,
    };
  }
  if (comment) {
    precase = {
      ...precase,
      observaciones: comment,
    };
  }
  return precase;
};

//Actualización de store de flow
//Pasa la data de las coberturas disponibles al store
export const setActiveCoverage = (activeCoverage) => ({
  type: sessionState.SET_ACTIVE_COVERAGE,
  payload: { activeCoverage },
});

//Pasa la data de la cobertura seleccionada al store
export const setActiveServiceCoverage = (activeServiceCoverage) => ({
  type: sessionState.SET_ACTIVE_SERVICE_COVERAGE,
  payload: { activeServiceCoverage },
});

//Pasa la configuración de ubicación al store (Origen y Destino)
export const setUbicationFlow = (ubicationState) => ({
  type: sessionState.SET_UBICATION,
  payload: { ubicationState },
});

//Pasa la configuración del cliente al store
export const setPersonFlow = (personState) => ({
  type: sessionState.SET_PERSONAL_INFO,
  payload: { personState },
});

//Pasa la lista de preguntas al store
export const setScript = (scriptState) => ({
  type: sessionState.SET_SCRIPT,
  payload: { scriptState },
});

//Actualiza el objecto precaso en el store
export const setActivePrecase = (precase) => ({
  type: sessionState.SET_PRECASE,
  payload: { precase },
});
