import { preCaso } from "./../types/plan";
import { sessionState } from "./../types/sessionState";

const initialState = {
	script: [],
	precase: preCaso,
	activeCoverage: [],
	activeServiceCoverage: {},
	activeUbicationConfig: {},
};

export const preCaseReducer = (state = initialState, action) => {
	switch (action.type) {
		case sessionState.SET_PRECASE:
			return {
				...state,
				precase: action.payload.precase,
			};
		case sessionState.SET_ACTIVE_COVERAGE:
			return {
				...state,
				activeCoverage: action.payload.activeCoverage,
			};
		case sessionState.SET_ACTIVE_SERVICE_COVERAGE:
			return {
				...state,
				activeServiceCoverage: action.payload.activeServiceCoverage,
			};
		case sessionState.SET_UBICATION:
			return {
				...state,
				activeUbicationConfig: action.payload.ubicationState,
			};
		case sessionState.SET_SCRIPT:
			return {
				...state,
				script: action.payload.scriptState,
			};
		case sessionState.CLEAR_PRECASE:
			return initialState;
		default:
			return state;
	}
};
