import { sessionState } from "../types/sessionState";

export const UseLocalStorage = ({ field, data = "", action }) => {
	switch (action) {
		case sessionState.GET_ITEM:
			return localStorage.getItem(field);
		case sessionState.SET_ITEM:
			localStorage.setItem(field, data);
			break;
		case sessionState.REMOVE_ITEM:
			localStorage.removeItem(field);
			break;
		case sessionState.CLEAR_ITEM:
			window.localStorage.clear();
			break;
		case sessionState.GET_ALL_ITEM:
			return localStorage;
		default:
			break;
	}
};
