import Swal from 'sweetalert2';
import { UseCookies } from '../Hooks/UseCookies';
import { setStorage, setCoockie } from '../helpers/localState';
import { webClientBase } from './../Services/webClientBase';
import { startLoading, finishLoading } from './ui';
import { startUpdateTracking } from './auth';
import { sessionState } from './../types/sessionState';

/*
Metodos dedicado al control del flujo del usuario en la aplicacion
*/

// obtiene los servicios de la aplicacion ya sea absent o planes
export const startServices = (sponsor, flujo) => {
  return (dispatch, getState) => {
    const { rut } = getState().userLogin;
    const tokenAuth = UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE });
    dispatch(startLoading());
    webClientBase.plan
      .getTypeServices(sponsor.toString(), flujo.toString(), tokenAuth, rut)
      .then(async (session) => {
        const { data: services } = await JSON.parse(session);
        dispatch(getService(services));
        dispatch(
          startUpdateTracking({
            request: `Obtener tipos servicio: servicios obtenidos, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(
          startUpdateTracking({
            request: `Obtener tipos servicio: ${error.message}, type: error`,
          })
        );

        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') return;
        dispatch(finishLoading());
      });
  };
};

// Inicializa los campos del formulario absent
export const startFields = (plan) => {
  return (dispatch, getState) => {
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .getFields(plan, tokenAuth)
      .then(async (session) => {
        const { data: fields } = await JSON.parse(session);
        setCoockie('fields', fields);
        dispatch(getFields(fields));
        dispatch(finishLoading());
        dispatch(
          startUpdateTracking({
            request: `Obtiene campos: servicios obtenidos, type: success`,
          })
        );
      })
      .catch((error) => {
        dispatch(
          startUpdateTracking({
            request: `Obtiene campos: ${error.message}, type: error`,
          })
        );
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') return;
        dispatch(finishLoading());
      });
  };
};

// Logica de flujo absent
//Genera la creación de plan de asistencia absent flujo 1
export const startCreateAssistanceAbsent = (asistencia) => {
  return (dispatch, getState) => {
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .createAssistanceAbsent(asistencia, tokenAuth)
      .then(async (session) => {
        const { data: assistenceCoverage } = await JSON.parse(session);
        const asistenciaSt = {
          producto: '',
          origen: 'absentAsistencia',
          tipo: 'absent',
          nombreMarca: asistencia.marcaVehiculo,
          modelo: asistencia.modeloVehiculo,
          patente: asistencia.patente,
        };
        setStorage('product', asistenciaSt);
        setStorage('coverage', assistenceCoverage);
        dispatch(setAssistanceAbsent(assistenceCoverage));
        dispatch(
          startUpdateTracking({
            request: `Crea asistencia absent: creado, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          Swal.fire('ATENCIÓN', error.message, 'info');
          dispatch(
            startUpdateTracking({
              request: `Crea asistencia absent: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};
//Genera la creación de plan de garantia absent flujo 2
export const startCreateWarrantyAbsent = (garantia) => {
  return (dispatch, getState) => {
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    if (garantia.file.length > 10) {
      const extension = garantia.file.split(';')[0].split('/')[1];
      const ticket = {
        file: garantia.file,
        name: 'ticket_idCaso.' + extension,
      };
      setStorage('ticket', ticket);
    }
    dispatch(startLoading());
    webClientBase.plan
      .createWarrantyAbsent(garantia, tokenAuth)
      .then(async (session) => {
        const { data: warrantyCoverage } = await JSON.parse(session);
        const garantiaSt = {
          producto: garantia.producto,
          origen: 'absentGarantia',
          tipo: 'absent',
          nombreMarca: garantia.nombreMarca,
          modelo: garantia.modelo,
          patente: '',
        };
        setStorage('product', garantiaSt);
        setStorage('coverage', warrantyCoverage);
        dispatch(setWarrantyAbsent(warrantyCoverage));
        dispatch(
          startUpdateTracking({
            request: `Crea garantia absent: creado, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          Swal.fire('ATENCIÓN', error.message, 'info');
          dispatch(
            startUpdateTracking({
              request: `Crea garantia absent: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};

// Logica de flujo plan
//Obtiene los planes de asistencia flujo 1
export const startGetAssistencePlan = (activeServiceSelected) => {
  return (dispatch, getState) => {
    //convertir activeService a objeto plan
    const asistenciaPlan = {
      rut: '',
      sponsor: Number(activeServiceSelected.sponsor),
      idTipoServicio: Number(activeServiceSelected.idTipoServicio),
      idTipoContrato: Number(activeServiceSelected.idContratoTipo),
    };
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .getAssistencePlan(asistenciaPlan, tokenAuth)
      .then(async (session) => {
        const { data: assistenceMaterial } = await JSON.parse(session);
        setStorage('insuredMaterial', assistenceMaterial);
        dispatch(setInsuredMaterial(assistenceMaterial));
        dispatch(
          startUpdateTracking({
            request: `Obtiene asistencia plan: creado, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          dispatch(
            startUpdateTracking({
              request: `Obtiene asistencia plan: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};
//Obtiene los planes de garantia flujo 2
export const startGetWarrantyPlan = (activeServiceSelected) => {
  return (dispatch, getState) => {
    //convertir activeService a objeto plan
    const garantiaPlan = {
      rut: '',
      idSponsor: activeServiceSelected.sponsor.toString(),
      data: activeServiceSelected.idTipoServicio.toString(),
    };
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .getWarrantyPlan(garantiaPlan, tokenAuth)
      .then(async (session) => {
        const { data: warrantyMaterial } = await JSON.parse(session);
        setStorage('insuredMaterial', warrantyMaterial);
        dispatch(setInsuredMaterial(warrantyMaterial));
        dispatch(
          startUpdateTracking({
            request: `Obtiene garantia plan: creado, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          dispatch(
            startUpdateTracking({
              request: `Obtiene garantia plan: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};
//Obtiene los planes de garantia asociado a un número de boleta
export const startGetWarrantyTicketPlan = (ticket, sponsor) => {
  return (dispatch) => {
    //convertir activeService a objeto plan
    const boletaPlan = {
      rut: '',
      idSponsor: sponsor.toString(),
      data: ticket.toString(),
    };
    const tokenAuth = UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE });
    dispatch(startLoading());
    webClientBase.plan
      .getTicketPlan(boletaPlan, tokenAuth)
      .then(async (session) => {
        const { data: ticketProduct } = await JSON.parse(session);
        dispatch(setInsuredMaterial(ticketProduct));
        dispatch(
          startUpdateTracking({
            request: `Obtiene boleta garantia plan: creado, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          dispatch(
            startUpdateTracking({
              request: `Obtiene boleta garantia plan: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};

//Aqui empezamos a actualizar el precaso
//Se buscan las coberturas asociados a la materia seleccionada este objeto viene con su configuraciones
export const startGetCoverage = (selectMaterial, htmlMateriaAsegurada, materiasegurada) => {
  return (dispatch, getState) => {
    const { data: token } = getState().auth;
    const tokenAuth = !token
      ? UseCookies({ field: 'accessToken', action: sessionState.GET_COOKIE })
      : token;
    dispatch(startLoading());
    webClientBase.plan
      .getCovers(selectMaterial, tokenAuth)
      .then(async (session) => {
        const { data: materialCoverage } = await JSON.parse(session);
        const infoProduct =
          materiasegurada.replace(',', '').length > 0 ? materiasegurada : htmlMateriaAsegurada;
        const product = {
          producto: infoProduct.split(',')[0],
          origen: 'MateriaAsegurada',
          tipo: 'plan',
          nombreMarca: infoProduct.split(',')[1],
          modelo: infoProduct.split(',')[2],
          patente: infoProduct.split(',')[3],
        };
        setStorage('product', product);
        setStorage('coverage', materialCoverage);
        dispatch(setAssistanceAbsent(materialCoverage));
        dispatch(
          startUpdateTracking({
            request: `obtención de cobertura: se encontraron, type: success`,
          })
        );

        dispatch(finishLoading());
      })
      .catch((error) => {
        if (error.message === 'UN AUTHORIZE SESSION EXPIRED 401') {
          return;
        } else {
          Swal.fire('ATENCIÓN', error.message, 'info');
          dispatch(
            startUpdateTracking({
              request: `obtención de cobertura: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};

// Actualización del store redux con los servicios
// Se asigna lso servicios asociados a un flujo y sponsor al store
export const getService = (data) => ({
  type: sessionState.GET_SERVICES,
  payload: {
    data,
  },
});
// Se asigna los campos del servicio seleccionado al store
export const getFields = (data) => ({
  type: sessionState.GET_FIELDS,
  payload: {
    data,
  },
});
// Se asigna el servicio seleccionado al store
export const activeService = (activeService) => ({
  type: sessionState.ACTIVE_SERVICE,
  payload: { activeService },
});
// Se asigna la asistencia absent creado al store
export const setAssistanceAbsent = (assistenceCoverage) => ({
  type: sessionState.SET_ASSISTANCE_ABSENT,
  payload: { assistenceCoverage },
});
// Se asigna la garantia absent creado al store
export const setWarrantyAbsent = (warrantyCoverage) => ({
  type: sessionState.SET_WARRANTY_ABSENT,
  payload: { warrantyCoverage },
});
// Se asigna la materia asegurada seleccionada al store
export const setInsuredMaterial = (insuredMaterialSelect) => ({
  type: sessionState.SET_INSURED_MATERIAL,
  payload: { insuredMaterialSelect },
});
// Se asigna la asistencia seleccionada al store
export const setAssistance = () => ({
  type: sessionState.SET_ASSISTANCE,
});
// Se asigna la garantia seleccionada al store
export const setWarranty = () => ({
  type: sessionState.SET_WARRANTY,
});
// Limpia el plan almacenado en el store
export const setClearPlan = () => ({
  type: sessionState.CLEAR_PRECASE,
});

export const setResetPlan = () => ({
  type: sessionState.RESET_PLAN,
});
