import Swal from 'sweetalert2';
import { webClientBase } from './../Services/webClientBase';
import { UseCookies } from '../Hooks/UseCookies';
import {
  startLoading,
  finishLoading,
  uiSetError,
  uiSetPageStyle,
  uiRemoveError,
  uiResetPageStyle,
} from './ui';
import { sessionState } from './../types/sessionState';
import { userLogin, origen } from '../types/user';
import { getBrowserInfo } from './../helpers/tracking';
import { removeCoockie, removeStorageItemWithUrl } from '../helpers/localState';

/*
Metodos dedicado al control de la autenticacion del usuario
*/
//Valida si el usuario esta autenticado
export const startLogin = (user) => {
  userLogin.rut = user.rut;
  userLogin.nroDocumento = user.rut;
  userLogin.clave = '*********';
  userLogin.idOrigin = Number(user.origin);
  userLogin.idOrigen = Number(user.origin);
  return (dispatch) => {
    dispatch(startLoading());
    let guid = UseCookies({
      field: 'sessionGuid',
      action: sessionState.GET_COOKIE,
    });
    userLogin.guid = guid;
    dispatch(preAsignLogin(userLogin));
    webClientBase.auth
      .login({ ...user, guid })
      .then(async (session) => {
        const { data } = await JSON.parse(session);
        const { token, guid } = data;
        setGuidCoockie({ guid });
        dispatch(sessionGUIDStore(guid));
        setAccessCoockie({ accessToken: token });
        dispatch(login(token));
        removeStorageItemWithUrl('TypeService');
        dispatch(
          startUpdateTracking({
            request: `Inicio de Sesión: usuario logueado, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch(async (error) => {
        if (error?.response) {
          const { data, mensaje } = await JSON.parse(error?.result);
          const { token, guid } = data;
          setGuidCoockie({ guid });
          dispatch(sessionGUIDStore(guid));
          setAccessCoockie({ accessToken: token });
          dispatch(login(token));
          dispatch(restoreSession());
          dispatch(
            startUpdateTracking({
              request: `Inicio de Sesión: ${mensaje}, type: success`,
            })
          );
        } else {
          user.idOrigin === origen.manual.toString() &&
            Swal.fire('INICIO DE SESIÓN', 'No se logro autenticar intentelo nuevamente', 'info').then(
              (result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                }
              }
            );
          dispatch(
            startUpdateTracking({
              request: `Inicio de Sesión: ${error.message}, type: error`,
            })
          );
        }
        dispatch(finishLoading());
      });
  };
};
// registra un usuario
export const startRegister = (user) => {
  const guid = UseCookies({
    field: 'sessionGuid',
    action: sessionState.GET_COOKIE,
  });
  return (dispatch) => {
    dispatch(startLoading());
    webClientBase.auth
      .register({ ...user, guid })
      .then(async (session) => {
        const { data: token, mensaje } = await JSON.parse(session);
        if (user.idOrigen !== origen.manual) {
          setAccessCoockie({ accessToken: token, idOrigin: user.id_origin });
          Swal.fire('REGISTRO DE USUARIO', mensaje, 'success');
        }
        user.idOrigen === origen.manual && dispatch(startSendSMS(user.nroDocumento));
        dispatch(
          startUpdateTracking({
            request: `Registro de Usuario: ${mensaje}, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        let mensaje = error.message;
        if (mensaje.includes('Rut ya ha sido ingresado en la base de datos'))
          mensaje =
            'Para el DNI indicado ya se encuentra ingresado. Vuelve al inicio y selecciona Recuperar Contraseña.';
        Swal.fire('REGISTRO DE USUARIO', 'Se ha presentado un inconveniente, intente nuevamente.', 'info');
        mensaje = error.message;

        dispatch(uiSetError('El rut ya esta registrado'));
        dispatch(
          startUpdateTracking({
            request: `Registro de Usuario: ${error.message}, type: error`,
          })
        );
        dispatch(finishLoading());
      });
  };
};
// registra un usuario generico por medio de url sms
export const startGenericRegister = (info) => {
  const dataDecode = atob(info.data);
  const dataArray = dataDecode.split('/');
  const data = {
    data: info.data,
    guidSession: info.session,
    origen: Number(info.origin),
  };
  return (dispatch) => {
    dispatch(startLoading());
    webClientBase.auth
      .registerGeneric(data)
      .then(async (session) => {
        const { data, mensaje } = await JSON.parse(session);
        const { token, guid } = data;
        setAccessCoockie({ accessToken: token });
        setGuidCoockie({ guid });
        userLogin.rut = dataArray[0];
        userLogin.nroDocumento = dataArray[0];
        userLogin.telefono = dataArray[1];
        userLogin.clave = '*********';
        userLogin.idOrigin = Number(info.origin);
        userLogin.idOrigen = Number(info.origin);
        userLogin.guid = guid;
        dispatch(preAsignLogin(userLogin));
        dispatch(
          startUpdateTracking({
            request: `Registro de Usuario Generico: ${mensaje}, type: success`,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        let mensaje = error.message;
        if (mensaje.includes('El rut ya esta registrado, debera iniciar sesión'))
          mensaje = 'Tu usuario ya se encuentra registrado. Debe iniciar sesión';
        Swal.fire('INICIO SESIÓN', mensaje, 'info').then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/view/login';
            dispatch(
              startUpdateTracking({
                request: `Registro de Usuario Generico: ${error.message}, type: error`,
              })
            );
          }
        });

        dispatch(finishLoading());
      });
  };
};

// flujo para el cambio de contraseña o confirmacion de nuevo usuario
//Envia el sms al telefono del usuario registrado
export const startSendSMS = (dni) => {
  return (dispatch) => {
    webClientBase.auth
      .sendSMS(dni)
      .then(async () => {
        userLogin.rut = dni;
        dispatch(preAsignLogin(userLogin));
      })
      .catch((error) => {
        Swal.fire("Recuperación de contraseña", "N° documento no existente en sistema", "error");
        dispatch(
          startUpdateTracking({
            request: `SMS: ${error.message}, type: error`,
          })
        );
      });
  };
};
//Envia un whatsapp al telefono del usuario registrado
export const startSendWSP = (dni) => {
  return (dispatch) => {
    webClientBase.auth
      .sendWSP(dni)
      .then(async () => {
        userLogin.rut = dni;
        dispatch(preAsignLogin(userLogin));
      })
      .catch((error) => {
        dispatch(
          startUpdateTracking({
            request: `WSP: ${error.message}, type: error`,
          })
        );
      });
  };
};

//Valida los codigos recibido si son iguales a lo que se envio en el sms
export const startSendCode = (dni, code) => {
  return (dispatch, getState) => {
    const { userLogin } = getState().auth;
    webClientBase.auth
      .sendVerifingCode(dni, code)
      .then(async (session) => {
        const { data: smsCode } = await JSON.parse(session);
        dispatch(setSMSCode(smsCode));
        userLogin?.idOrigen === origen.manual &&
          Swal.fire('REGISTRO DE USUARIO', 'Usuario registrado exitosamente', 'success');
        /*         dispatch(
          startUpdateTracking({
            request: `Validación SMS: codigo validado, type: success`,
          })
        ); */
      })
      .catch((error) => {
        Swal.fire(
          'Validación de Codigo',
          'Su codigo de verificación no se logro validar. Intente nuevamente',
          'info'
        );
        dispatch(
          startUpdateTracking({
            request: `Validación SMS: ${error.message}, type: error`,
          })
        );
      });
  };
};
// una vez validado el codigo, se le permite cambiar la contraseña
export const startChangePassword = (password, authToken) => {
  return (dispatch) => {
    dispatch(startLoading());
    webClientBase.auth
      .sendNewPassword(password, authToken)
      .then(async (session) => {
        const { data } = await JSON.parse(session);
        dispatch(setSMSCode(data));
        dispatch(finishLoading());
        const { mensaje } = await JSON.parse(session);
        Swal.fire('RECUPERACIÓN DE CONTRASEÑA', 'Se ha actualizado tu clave correctamente', 'success');
        dispatch(
          startUpdateTracking({
            request: `Recuperación de contraseña: ${mensaje}, type: success`,
          })
        );
      })
      .catch((error) => {
        //Swal.fire("Recuperación de contraseña", "N° documento no existente en sistema", "error");
        dispatch(
          startUpdateTracking({
            request: `Recuperación de contraseña: ${error.message}, type: error`,
          })
        );
        dispatch(finishLoading());
      });
  };
};
//Flujo de seguimiento de navegación
//Crea el registro de seguimiento
export const startAddNewTracking = () => {
  return async (dispatch, getState) => {
    const { session: guid } = getState().auth;
    if (guid) {
      setGuidCoockie({ guid });
      return;
    }
    const guidCoockie = UseCookies({
      field: 'sessionGuid',
      action: sessionState.GET_COOKIE,
    });
    if (guidCoockie) {
      dispatch(sessionGUIDStore(guidCoockie));
      return;
    }
    const tracking = await getBrowserInfo({
      guid: guid,
      request: 'entrando al sitio...',
      origin: 'web',
    });
    webClientBase.auth
      .addNewTracking(tracking)
      .then(async (session) => {
        const { data } = await JSON.parse(session);
        setGuidCoockie({ guid: data });
        dispatch(sessionGUIDStore(data));
        dispatch(uiRemoveError());
      })
      .catch(() => {
        dispatch(uiSetError('No se ha generado el estado'));
      });
  };
};
//Actualiza el tracking del usuario
export const startUpdateTracking = ({ request, origin }) => {
  return async (dispatch, getState) => {
    let guid;
    guid = getState().auth.session;
    if (!guid) {
      guid = UseCookies({
        field: 'sessionGuid',
        action: sessionState.GET_COOKIE,
      });
    }
    if (!guid) return;
    const tracking = await getBrowserInfo({ guid: guid, request, origin });
    webClientBase.auth
      .updateTracking(tracking)
      .then(async (session) => {
        const { data } = await JSON.parse(session);
        if (data?.guid) {
          setGuidCoockie({ guid: data });
          dispatch(sessionGUIDStore(data));
        }

        return;
      })
      .catch(() => {
        return;
      });
  };
};
// cierra la sesion del usuario eliminando las coockies y local storage
export const startLogout = () => {
  return async (dispatch) => {
    removeCoockie('authToken');
    removeCoockie('accessToken');
    setTimeout(() => {
      Swal.fire('SESIÓN', 'Su sesión ha finalizado', 'info').then((result) => {
        if (result.isConfirmed) {
          dispatch(
            startUpdateTracking({
              request: `Session: Su sesión a finalizado, type: info`,
            })
          );
          dispatch(logout());
          dispatch(startAddNewTracking());
          console.clear();
        }
      });
    }, 1200);
  };
};
// Aqui se configura el diseño del sitio colores y logo
export const startSyncStyle = (sponsor) => {
  return (dispatch) => {
    webClientBase.auth
      .setPageStyle(sponsor.toString())
      .then(async (session) => {
        const { data: style } = await JSON.parse(session);
        UseCookies({
          field: 'style',
          data: style,
          action: sessionState.SET_COOKIE,
          age: 172800,
          path: '/',
        });
        dispatch(uiSetPageStyle(style));
      })
      .catch(async () => {
        dispatch(uiResetPageStyle());
        UseCookies({ field: 'style', action: sessionState.REMOVE_COOKIE, path: '/' });
        window.location.replace(`https://${window.location.host}/view/HomePage`);
      });
  };
};


export const startGetDocuments=(id)=>{
  return(dispatch) =>{
    webClientBase.auth.getTypesDocuments(id).then(
      async (typesDocuments)=>{
        console.log(typesDocuments);
         const {data:typeDocument}=await JSON.parse(typesDocuments);
         dispatch(setTypesDocuments(typeDocument))
      }
    )
  }

}

// Metodos de gestion de informacion persistente en la session activa.
// actualiza el store con la informacion del token del usuario
export const sessionGUIDStore = (session) => ({
  type: sessionState.sessionGUID,
  payload: {
    session,
  },
});

export const login = (data) => ({
  type: sessionState.LOGIN,
  payload: {
    data,
  },
});
export const restoreSession = () => ({
  type: sessionState.RESTORE_SESSION,
  payload: {
    lastSession: true,
  },
});
// limpia el store de la informacion del usuario logueado
export const logout = () => ({
  type: sessionState.LOGOUT,
});
// actualiza el store con la informacion del usuario logueado generalmente por redes sociales
export const preAsignLogin = (userLogin) => ({
  type: sessionState.PRE_ASIGN_LOGIN,
  payload: {
    userLogin,
  },
});
// actualiza el store con la informacion del usuario registrado
export const preAsignRegister = (userRegistrer) => ({
  type: sessionState.PRE_ASIGN_REGISTER,
  payload: {
    userRegistrer,
  },
});
// actualiza el store con la estado del sms
export const setSMSCode = (smsCode) => ({
  type: sessionState.sendSMS,
  payload: {
    smsCode,
  },
});
// asigna y reasigna el estado de acceso dentro de la coockie
const setAccessCoockie = ({ accessToken, idOrigin = '0' }) => {
  const noExists = !UseCookies({
    field: 'accessToken',
    data: accessToken,
    path: '/',
    action: sessionState.GET_COOKIE,
  });
  if (noExists && idOrigin !== '3') {
    UseCookies({
      field: 'accessToken',
      data: accessToken,
      path: '/',
      action: sessionState.SET_COOKIE,
    });
  }
};

// asigna y reasigna el identificador dentro de la coockie
const setGuidCoockie = ({ guid }) => {
  const noExists = !UseCookies({
    field: 'sessionGuid',
    data: guid,
    path: '/',
    action: sessionState.GET_COOKIE,
  });
  if (noExists) {
    UseCookies({
      field: 'sessionGuid',
      data: guid,
      path: '/',
      action: sessionState.SET_COOKIE,
      age: 86400,
    });
  } else {
    const guidOn = UseCookies({
      field: 'sessionGuid',
      data: guid,
      path: '/',
      action: sessionState.GET_COOKIE,
    });
    if (guidOn !== guid) {
      UseCookies({
        field: 'sessionGuid',
        action: sessionState.REMOVE_COOKIE,
      });
      UseCookies({
        field: 'sessionGuid',
        data: guid,
        path: '/',
        action: sessionState.SET_COOKIE,
        age: 86400,
      });
    }
  }
};

const setTypesDocuments=(typeDocument)=>({
  type:sessionState.setTypeDocument,
  payload:{typeDocument}
})
