import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
//Componente encargado de redireccionar al usuario a la pagina de plan absent
const PlanNotFound = ({ title, question, button }) => {

	//Renderizado del componente
	const navigate = useNavigate();
	useEffect(() => {
      navigate('/view/auth/WithOutPlanRegister');
    }, []);

	return (
		<div className="mt25">
			<AiOutlineInfoCircle size={60} className="center-icon" />
			<h3 className="info-spinner-mt10">{title}</h3>
			<h4 className="info-spinner-mt10">{question}</h4>
			<Link to="/view/auth/WithOutPlanRegister" className="bttn bttn--blue font-white mb20">
				{button}
			</Link>
		</div>
	);
};

export default React.memo(PlanNotFound);
