import { Provider } from 'react-redux';
import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';
import './App.css';
import './style.css';
import './assets/materialize/css/materialize.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getConfigData } from './helpers/config';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';

//Inicializacion de la aplicacion
function App() {
  const persistor = persistStore(store);

  const appId = getConfigData(process.env.NODE_ENV).googleAuth;
  const clientId = `${appId}.apps.googleusercontent.com`;
  return (
    <div className="main">
      <GoogleOAuthProvider clientId={clientId}>
        <PersistGate loading={null} persistor={persistor}>
          <Provider store={store}>
            <AppRouter />
          </Provider>
        </PersistGate>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
