import React from "react";
import { BiLeftArrowAlt,BiRightArrowAlt } from "react-icons/bi";

const ArrowTitle = ({ handleReturn,handleConfirm, title, render }) => {
	return (
		<>
			<div className="header-secondary mb40">
				<BiLeftArrowAlt className="icon icon-link" onClick={handleReturn} />
				<h6 className="title__page-title-center">{title}</h6>
				{render}
				{handleConfirm && (
					<BiRightArrowAlt className=" icon icon-arrow-right icon-link " onClick={handleConfirm} />
				)}
				
			</div>
		</>
	);
};

export default React.memo(ArrowTitle);
