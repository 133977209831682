import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ItemIcon from './ItemIcon';
import { setStorage } from '../../../helpers/localState';
import { activeService, startGetAssistencePlan, startGetWarrantyPlan } from '../../../actions/plan';

const ServicesItem = ({
  sponsor,
  idTipoServicio,
  nombre,
  ruta,
  esAbsent,
  idContratoTipo,
  idFlujoTipoServicio,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nameS = nombre.toString().toLowerCase();
  const bUrl = '/view/auth/';
  const rute = esAbsent.toLowerCase() === 'si' ? `${bUrl}WithOutPlanRegister` : `${bUrl}InsuredMaterial`;
  const url = nameS.includes('gara') && esAbsent.toLowerCase() === 'si' ? `${bUrl}Ticket` : rute;
  //Se guarda en el localStorage el servicio seleccionado y llama los planes asociados
  const handleNavigate = () => {
    const selectService = {
      sponsor,
      idTipoServicio,
      idContratoTipo,
      idFlujoTipoServicio,
      ruta,
      nombre,
    };
    dispatch(activeService(selectService));
    selectService?.idFlujoTipoServicio === '1'
      ? dispatch(startGetAssistencePlan(selectService))
      : !url.includes('Ticket') && dispatch(startGetWarrantyPlan(selectService));
    setStorage('activeService', selectService);
    setTimeout(() => {
      navigate(url);
    }, 500);
  };
  //Renderiza el componente
  return (
    <div className="servicios__items">
      {
        <div id="serviceHandle" className="link-blue" onClick={handleNavigate}>
          <ItemIcon nombre={nombre} ruta={ruta} />
          <p className="url-button">{nombre}</p>
        </div>
      }
    </div>
  );
};

export default ServicesItem;
