import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoadSpiner } from '../../routers/LoadSpiner';
import ServicesItem from './ListService/ServicesItem';
import Swal from 'sweetalert2';
import { getStorageItems, removeStorageItemWithUrl } from '../../helpers/localState';
import { getParams, updateGoogleAnalitics } from '../../helpers/tracking';
import { startLogout, startUpdateTracking } from '../../actions/auth';
import { startServices } from '../../actions/plan';
import { Contenedor } from './../Contenedor';
import ErrorPage from '../../routers/ErrorPage';

function TypeService() {
  try {
    //Objeto para agilizar pruebas en test --> MockupData.js
    //se inicializan variables a utilizar en el componente
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { lastSession } = useSelector((state) => state.auth);
    const { flujo, sponsor } = getParams();
    const { service: Services } = useSelector((state) => state.plan);
    const [awaitServices, setAwaitServices] = useState(false);

    //Se ejecuta la actualización de Google Analitics y ingreso de pagina
    useEffect(() => {
      if (sponsor > 0 && flujo > 0) {
        dispatch(startServices(sponsor, flujo));
        Swal.fire({
          title: 'Atención',
          text: 'Tienes 60 minutos para solicitar tu servicio, pasado este tiempo ingresa nuevamente al registro o contáctate por los canales de atención disponible. ',
          allowOutsideClick: false,
          icon: 'info',
          confirmButtonText: 'Ok',
        }).then(() => {
          setAwaitServices(true);
        });
      }
      updateGoogleAnalitics();
      dispatch(startUpdateTracking({ request: 'ingresando a la pagina...' }));
    }, []);
    //Se revisa si existe una lista de servicios y se selecciona el primero
    useEffect(() => {
      setTimeout(() => {
        if (lastSession && Services.length > 0) {
          const { actualUrl } = getStorageItems();
          if (actualUrl === 'TypeService' || !actualUrl) return;
          Swal.fire({
            title: 'Parece que tiene un solicitud pendiente',
            text: '¿desea retormarla?',
            icon: 'info',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
          }).then((result) => {
            if (result.value) {
              navigate(`/view/auth/${actualUrl}`);
            } else {
              removeStorageItemWithUrl('TypeService');
            }
          });
        } else {
          setTimeout(() => {
            if (Services.length <= 0 && awaitServices) {
              Swal.fire({
                title: 'Atención',
                text: 'Parece que no tienes servicios disponibles. Favor vuelva a intentarlo mas tarde o realice la consulta por teléfono',
                allowOutsideClick: false,
                icon: 'info',
              }).then(() => {
                dispatch(startLogout());
              });
            }
          }, 4000);
        }
      }, 1300);
    }, [Services, awaitServices]);
    const component = (
      <>
        <h1 className="title__tertiary-title mb20">
          pedido de <br />
          servicio
        </h1>
        <div className="box-center mb40">
          <h3 className="title__form-subtitle mar-0">¿Qué tipo de servicio necesitas?</h3>
        </div>
        {Services?.length > 0 ? (
          <div className="servicios__box">
            {Services?.length > 0 && <div className="servicios__box--black" />}
            {Services?.map((service) => (
              <ServicesItem
                key={service.idTipoServicio}
                sponsor={sponsor}
                idTipoServicio={service.idTipoServicio}
                idContratoTipo={service.idContratoTipo}
                idFlujoTipoServicio={service.idFlujoTipoServicio}
                ruta={service.ruta}
                nombre={service.nombre}
                esAbsent={service.esAbsent}
              />
            ))}
            {Services?.length % 2 && <div className="servicios__items" />}
          </div>
        ) : (
          <div>
            <h3 className="typeService-spirnner">Cargando Servicios...</h3>
            <div className="spinner-position">
              <LoadSpiner />
            </div>
          </div>
        )}
      </>
    );
    //Renderizado del componente
    return <Contenedor component={component} />;
  } catch (error) {
    return <ErrorPage error={error} />;
  }
}

export default TypeService;
