import { apiPostPromise } from './../../helpers/callApi';
//Metodos de llamado a la API para la gestión del servicio
export const plan = ({ apiServiceUri, myHeaders }) => {
  return {
    getTypeServices: async function (sponsor, flujo, authToken, rut) {
      const dataBody = {
        rut: rut,
        idSponsor: sponsor,
        data: flujo,
      };
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(dataBody);
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-tipos-servicios`, myHeaders, raw, '');
    },
    getFields: async function (plan, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(plan);
      return await apiPostPromise(`${apiServiceUri}Servicios/obtiene-campos/`, myHeaders, raw, '');
    },
    createAssistanceAbsent: async function (asistencia, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(asistencia);
      return await apiPostPromise(`${apiServiceUri}Servicios/crea-asistencia-absent/`, myHeaders, raw, '');
    },
    createWarrantyAbsent: async function (garantia, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(garantia);
      return await apiPostPromise(`${apiServiceUri}Servicios/crea-garantia-absent`, myHeaders, raw, '');
    },
    getAssistencePlan: async function (asistencia, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(asistencia);
      return await apiPostPromise(
        `${apiServiceUri}Servicios/obtener-planes-asistencia`,
        myHeaders,
        raw,
        ''
      );
    },
    getWarrantyPlan: async function (garantia, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(garantia);
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-planes-garantia`, myHeaders, raw, '');
    },
    getTicketPlan: async function (boleta, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(boleta);
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-planes-boleta`, myHeaders, raw, '');
    },
    checkPlanAbsent: async function (plan, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(plan);
      return await apiPostPromise(`${apiServiceUri}Servicios/verifica-plan-absent`, myHeaders, raw, '');
    },
    updateCase: async function (caso, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(caso);
      return await apiPostPromise(`${apiServiceUri}Servicios/actualiza-precaso`, myHeaders, raw, '');
    },
    loadFile: async function (file, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(file);
      return await apiPostPromise(
        `${apiServiceUri}Servicios/cargar-archivo`,
        myHeaders,
        raw,
        'Archivo subido exitosamente'
      );
    },
    createCase: async function (authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = undefined;
      return await apiPostPromise(`${apiServiceUri}Servicios/generar-caso-mockys`, myHeaders, raw, 'ok');
    },
    getProductBrands: async function (idServicio, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const dataDto = {
        data: idServicio.toString(),
      };
      const raw = JSON.stringify(dataDto);
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-marcas-productos`, myHeaders, raw, '');
    },
    getCovers: async function (sponsorCoverturaPlanMateria, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(sponsorCoverturaPlanMateria);
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-coberturas`, myHeaders, raw, '');
    },
    getVehicleModels: async function (idMarcaVehiculo, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const dataDto = {
        data: idMarcaVehiculo.toString(),
      };
      const raw = JSON.stringify(dataDto);
      return await apiPostPromise(
        `${apiServiceUri}Servicios/obtener-modelos-vehiculos`,
        myHeaders,
        raw,
        ''
      );
    },
    getServiceLocation: async function (idServicio, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const dataDto = {
        data: idServicio.toString(),
      };
      const raw = JSON.stringify(dataDto);
      return await apiPostPromise(
        `${apiServiceUri}Servicios/obtener-ubicacion-servicio`,
        myHeaders,
        raw,
        ''
      );
    },
    getCommunes: async function (authToken, idpais) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = `"${idpais}"`;
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-comunas`, myHeaders, raw, '');
    },
    getWarrantyProducts: async function (authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = undefined;
      return await apiPostPromise(
        `${apiServiceUri}Servicios/obtener-productos-garantia`,
        myHeaders,
        raw,
        ''
      );
    },
    getVehicleMakes: async function (authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = undefined;
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-marcas-vehiculos`, myHeaders, raw, '');
    },
    getScript: async function (idCoberturaServicio, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const dataDto = {
        idCobertura: Number(idCoberturaServicio),
      };
      const raw = JSON.stringify(dataDto);
      return await apiPostPromise(`${apiServiceUri}Servicios/obtener-requerimientos`, myHeaders, raw, 'ok');
    },
    sendGetPersonal: async function (personalInfo, authToken) {
      myHeaders.delete('Authorization');
      myHeaders.append('Authorization', 'Bearer ' + authToken);
      const raw = JSON.stringify(personalInfo);
      return await apiPostPromise(`${apiServiceUri}Servicios/clienteInf`, myHeaders, raw, 'ok');
    },
    
  };
};
