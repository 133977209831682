import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from './../reducers/authReducer';
import { uiReducer } from './../reducers/uiReducer';
import {
  userLoginReducer,
  userPersonalInfoReducer,
  userRegisterReducer,
  userSMSReducer,
} from './../reducers/userReducer';
import { planReducer } from './../reducers/planReducer';
import { preCaseReducer } from './../reducers/preCaseReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { getConfigData } from '../helpers/config';

const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: getConfigData().facebookAuth,
      onError: function () {},
    }),
  ],
};

//Inicialización de Redux con su respectivo middleware y la libreria de Redux DevTools
const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;
//Inicializacion de los reducers
const reducers = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userSMS: userSMSReducer,
  plan: planReducer,
  preCase: preCaseReducer,
  personalInfo: userPersonalInfoReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

// Inicialización del store
export const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));
