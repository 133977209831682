import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadInfo from '../../Generics/HeadInfo';
import MapGoogle from './map/MapGoogle';
import Swal from 'sweetalert2';
import { getStorageItems, removeStorageItemWithUrl, setStorage } from '../../../../helpers/localState';
import { updateGoogleAnalitics } from '../../../../helpers/tracking';
import { startUpdateTracking } from '../../../../actions/auth';
import { startGetUpdatePersonalInfo, startUpdatePrecase } from '../../../../actions/flow';
import { Contenedor } from '../../../Contenedor';
import ErrorPage from '../../../../routers/ErrorPage';
import ArrowTitle from '../../../ArrowTitle';

function UbicationAndDestiny() {
  try {
    //Objeto para agilizar pruebas en test --> MockupData.js
    //se inicializan variables a utilizar en el componente
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [enableButton] = useState(true);
    const { activeCoverage, ubicationState, activeService, product, activeScript } = getStorageItems();

    const esOrigenDestino = ubicationState?.esOrigenDestino ? ubicationState?.esOrigenDestino : false;

    const handleReturn = () => {
      if (activeCoverage?.length === 1) {
        removeStorageItemWithUrl('CoverageService');
        navigate('/view/auth/CoverageService');
        return;
      }
      removeStorageItemWithUrl('Service');
      navigate('/view/auth/Service/');
    };

    const [originStreet, setOriginStreet] = useState('');
    const [destinyStreet, setDestinyStreet] = useState('');
    const [originCity, setOriginCity] = useState('');
    const [destinyCity, setDestinyCity] = useState('');
    const [confirmDirection, setConfirmDirection] = useState(false);
    const [selected, setSelected] = useState(null);
    const [selected2, setSelected2] = useState(null);
    const [directionsResponse, setDirectionsResponse] = useState(null);

    const handleSubmit = (e) => {
      e.preventDefault();
      if (esOrigenDestino && !directionsResponse) {
        Swal.fire({
          title: 'Dirección',
          text: 'Debe calcular la distancia de las direcciones.',
          icon: 'info',
          confirmButtonText: 'Ok',
        });
        return;
      }
      if (!selected) {
        Swal.fire({
          title: 'DIRECCIÓN',
          text: 'Debes ingresar una ubicación',
          icon: 'info',
          confirmButtonText: 'Ok',
        });
        return;
      }
      if (!confirmDirection) {
        Swal.fire({
          title: 'DIRECCIÓN',
          text: 'Debes confirmar la ubicación',
          icon: 'info',
          confirmButtonText: 'Ok',
        });
        return;
      }
      if (activeScript?.length < 1 || !activeScript) {
        Swal.fire('ATENCIÓN', 'No se ha encontrado script activo para este servicio', 'warning').then(
          (result) => {
            if (result.isConfirmed) {
              navigate('/view/auth/TypeService/');
              removeStorageItemWithUrl('TypeService');
            }
          }
        );
        return;
      }
      const distance = directionsResponse?.routes[0].legs[0].distance.value
        ? directionsResponse?.routes[0].legs[0].distance.value
        : 0;
      const duration = directionsResponse?.routes[0].legs[0].duration.value
        ? directionsResponse?.routes[0].legs[0].duration.value
        : 0;
      const ubication = {
        origen: { ...selected },
        destino: { ...selected2 },
        distance: {
          distance: distance,
          duration: duration,
        },
      };
      const departOrigin = document.getElementById('departOrigin')?.value;
      const departDestiny = document.getElementById('departDestiny')?.value;
      const street = {
        originStreet: `${originStreet} ${departOrigin}`.replace('undefined', ''),
        destinyStreet: `${destinyStreet} ${departDestiny}`.replace('undefined', ''),
        originCity: originCity,
        destinyCity: destinyCity,
      };
      setStorage('street', street);
      setStorage('point', ubication);
      dispatch(startUpdatePrecase());
      navigate('/view/auth/RequestType/');
    };

    // Se ejecuta la actualización de Google Analitics y ingreso de pagina
    useEffect(() => {
      Swal.fire('UBICACIÓN', 'Recuerde ingresar la numeración exacta para una mejor experiencia', 'info');
      updateGoogleAnalitics();
      dispatch(startUpdateTracking({ request: 'ingresando a la pagina...' }));
      dispatch(startGetUpdatePersonalInfo());
    }, []);

    const title = `${activeService?.nombre} ${product?.nombreMarca} ${product?.producto} ${product?.modelo} ${product?.patente}`;

    const component = (
      <>
        <ArrowTitle
          handleReturn={handleReturn}
          handleConfirm={() => document.getElementById('confirmForm').click()}
          title="pedido de servicio"
        />
        <HeadInfo title={title} activeService={activeService} />
        <form onSubmit={handleSubmit} className="forms-4" autoComplete="off">
          <div>
            <h4 className="title__form-subtitle--bold mb30">Ingrese la Ubicación del servicio:</h4>
            <MapGoogle
              esOrigenDestino={esOrigenDestino}
              selected={selected}
              setSelected={setSelected}
              selected2={selected2}
              setSelected2={setSelected2}
              directionsResponse={directionsResponse}
              setDirectionsResponse={setDirectionsResponse}
              setOriginStreet={setOriginStreet}
              setDestinyStreet={setDestinyStreet}
              setOriginCity={setOriginCity}
              setDestinyCity={setDestinyCity}
              setConfirmDirection={setConfirmDirection}
              confirmDirection={confirmDirection}
              originStreet={originStreet}
              destinyStreet={destinyStreet}
            />
          </div>
          <button
            id="confirmForm"
            type="submit"
            className={enableButton ? 'bttn bttn--blue mb20' : 'bttn bttn--gray mb20'}
            disabled={!enableButton}
            hidden={enableButton}
          >
            continuar
          </button>
        </form>
      </>
    );
    //Renderizado del componente
    return <Contenedor component={component} />;
  } catch (error) {
    return <ErrorPage error={error} />;
  }
}

export default UbicationAndDestiny;
