import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoadSpiner } from "../LoadSpiner";
import { HomePage } from './HomePage';

export const LoginRoute = () => {
	//Se deja el lazy para que se cargue solo cuando se necesite
	const Login = lazy(() => import("../../Components/Login/Login"));
	const LoginGeneric = lazy(() => import("../../Components/Login/LoginGeneric"));
	const VerifyNewPassword = lazy(() => import("../../Components/Login/ForgetMyPassword/VerifyNewPassword"));
	const VerifyForgetPassword = lazy(() => import("../../Components/Login/ForgetMyPassword/VerifyForgetPassword"));
	const ForgetMyPassword = lazy(() => import("../../Components/Login/ForgetMyPassword/ForgetMyPassword"));
	const RegisterVerify = lazy(() => import("../../Components/Register/RegisterVerify"));
	const WithOutSocialRegister = lazy(() => import("../../Components/Register/WithOutSocialRegister"));
	const WithSocialRegister = lazy(() => import("../../Components/Register/WithSocialRegister"));


	return (
		<Suspense
			fallback={
				<div className="spinner-position">
					<LoadSpiner />
				</div>
			}
		>
			<Routes>
				<Route path="/HomePage" element={<HomePage />} />
				<Route path="/login" element={<Login />} />
				<Route path="/SMS" element={<LoginGeneric />} />
				<Route //Logica de recuperacion contraseña
					path="/verifyNewPassword" // 1
					element={<VerifyNewPassword />}
				/>
				<Route
					path="/verifyForgetPassword" // 2
					element={<VerifyForgetPassword />}
				/>
				<Route
					path="/forgetMyPassword" // 3
					element={<ForgetMyPassword />}
				/>
				<Route path="/registerVerify" element={<RegisterVerify />} />
				<Route path="/register" element={<WithOutSocialRegister />} />
				<Route path="/registerSocial" element={<WithSocialRegister />} />
				<Route path="*" element={<Navigate to="/view/HomePage" />} />
			</Routes>
		</Suspense>
	);
};
