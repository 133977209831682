import React, { useCallback, useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { LoadSpiner } from '../../../../../routers/LoadSpiner';
import { Combobox, ComboboxInput, ComboboxPopover, ComboboxList, ComboboxOption } from '@reach/combobox';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import Swal from 'sweetalert2';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';
import { UseCookies } from '../../../../../Hooks/UseCookies';
import { getConfigData } from '../../../../../helpers/config';
import { sessionState } from '../../../../../types/sessionState';
import { BiX } from 'react-icons/bi';

const libraries = ['places'];

const MapGoogle = ({
  esOrigenDestino,
  selected,
  setSelected,
  selected2,
  setSelected2,
  directionsResponse,
  setDirectionsResponse,
  setOriginStreet,
  setDestinyStreet,
  setOriginCity,
  setDestinyCity,
  confirmDirection,
  setConfirmDirection,
  originStreet,
  destinyStreet,
}) => {
  //Inicializacion api google maps
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getConfigData(process.env.NODE_ENV).googleMaps,
    libraries: libraries,
  });

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  let setCenter;
  let defaultCountry = 'CL';

  const getDefaultCords = () => {
    switch (configPage?.pais.toString()) {
      case '1':
        setCenter = {
          lat: -33.418352,
          lng: -70.6015351,
        };
        defaultCountry = 'CL';
        break;
      case '2':
        setCenter = {
          lat: -12.093377,
          lng: -77.022707,
        };
        defaultCountry = 'PE';
        break;
      case '3':
        setCenter = {
          lat: 4.6742735,
          lng: -74.0476029,
        };
        defaultCountry = 'CO';
        break;
      default:
        break;
    }
  };

  const configPage = UseCookies({ field: 'config', action: sessionState.GET_COOKIE });

  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      setCenter = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    }, getDefaultCords);
  }
  const [initialStreet, setInitialStreet] = useState('');
  function getReverseGeocodingData(lat, lng) {
    var latlng = new window.google.maps.LatLng(lat, lng);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const address = results[0].formatted_address;
        const directions = results[0].address_components;
        directions.forEach((direction) => {
          if (direction.types[0] === 'administrative_area_level_3') {
            setOriginCity(direction.long_name);
          }
        });
        setInitialStreet(address);
        setOriginStreet(address);
        setSelected({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
      }
    });
  }

  //Esta es la referencia del punto de origen
  const mapState = { center: setCenter };
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [map, setMap] = useState(null);
  const mapRef = useRef();
  //Inicialización del mapa
  const onLoad = useCallback(function callback(map) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const bounds = new window.google.maps.LatLngBounds({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          map.fitBounds(bounds);
          setMap(map);
          getReverseGeocodingData(position.coords.latitude, position.coords.longitude);
        },
        () => {
          getDefaultCords();
          const bounds = new window.google.maps.LatLngBounds(setCenter);
          map.fitBounds(bounds);
          setMap(map);
          getReverseGeocodingData(setCenter.lat, setCenter.lng);
        }
      );
    }
    mapRef.current = map;
  }, []);

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        const bounds = new window.google.maps.LatLngBounds({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        map.fitBounds(bounds);
        setMap(map);
      });
    } else {
      const bounds = new window.google.maps.LatLngBounds(mapState.center);
      map.fitBounds(bounds);
      setMap(map);
    }
    mapRef.current = map;
  }, [navigator.geolocation]);

  //En caso de desmontar el mapa se limpia el mapa
  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);
  //Cuando se actualize la direccion de origen se actualiza el mapa
  useEffect(() => {
    if (isLoaded) {
      mapRef.current.panTo(selected);
    }
  }, [selected]);
  //Cuando se actualize la direccion de destino se actualiza el mapa
  useEffect(() => {
    if (isLoaded) {
      mapRef.current.panTo(selected2);
    }
  }, [selected2]);
  //Configuraciones iniciales del mapa
  const Configuration = {
    streetViewControl: false,
    mapTypeControl: false,
    keyboardShortcuts: false,
    zoomControl: false,
    minZoom: 6,
    maxZoom: 19,
    gestureHandling: 'cooperative',
  };
  const [routeButton, setRouteButton] = useState(true);
  const childOrigin = useRef(null);
  const childDesti = useRef(null);
  //Calcula la distancia entre dos puntos
  async function calculateRoute() {
    if (!selected) {
      Swal.fire('UBICACIÓN', 'Debe seleccionar una dirección existente', 'info');
      childOrigin.current.clearInput();
      childDesti.current.clearInput();
      return;
    }
    if (!selected2 && esOrigenDestino) {
      Swal.fire('UBICACIÓN', 'Debe seleccionar una dirección existente', 'info');
      childDesti.current.clearInput();
      return;
    }
    if (!selected && !selected2) {
      return;
    }
    selected.lat = parseFloat(selected.lat);
    selected.lng = parseFloat(selected.lng);
    selected2.lat = parseFloat(selected2.lat);
    selected2.lng = parseFloat(selected2.lng);
    const directionsService = new window.google.maps.DirectionsService();
    const request = {
      origin: new window.google.maps.LatLng(selected.lat, selected.lng),
      destination: new window.google.maps.LatLng(selected2.lat, selected2.lng),
      travelMode: window.google.maps.TravelMode.DRIVING,
    };
    directionsService.route(request, function (result, status) {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirectionsResponse(result);
      }
    });
    setConfirmDirection(true);
    setRouteButton(false);
  }

  const confirmRoute = () => {
    setConfirmDirection(true);
    setRouteButton(false);
  };

  useEffect(() => {
    if (confirmDirection && !esOrigenDestino) {
      document.getElementById('confirmForm').click();
      return;
    }
    if (confirmDirection && esOrigenDestino && directionsResponse) {
      document.getElementById('confirmForm').click();
      return;
    }
  }, [confirmDirection, directionsResponse]);

  //Renderizado del componente Principal
  return isLoaded ? (
    <>
      <div className="mb25 h60">
        <label className="label-react-select">Desde</label>
        <PlacesAutocomplete
          placeholder={'origen'}
          setSelected={setSelected}
          countryZone={defaultCountry}
          setStreet={setOriginStreet}
          setCity={setOriginCity}
          initialStreet={initialStreet}
          setConfirmDirection={setConfirmDirection}
          setRouteButton={setRouteButton}
          street={originStreet}
          ref={childOrigin}
        />
        <Depart id={'departOrigin'} />
      </div>
      {!esOrigenDestino && (
        <button type="button" className="btn" onClick={confirmRoute} disabled={!routeButton}>
          Confirmar dirección
        </button>
      )}
      {esOrigenDestino && (
        <div className="mb25 h60">
          <label className="label-react-select">Hasta</label>
          <PlacesAutocomplete
            placeholder={'destino'}
            setSelected={setSelected2}
            countryZone={defaultCountry}
            setStreet={setDestinyStreet}
            setCity={setDestinyCity}
            setConfirmDirection={setConfirmDirection}
            setRouteButton={setRouteButton}
            street={destinyStreet}
            ref={childDesti}
          />
          <Depart id={'departDestiny'} />
          <button type="button" className="btn" onClick={calculateRoute} disabled={!routeButton}>
            Confirmar dirección y calcular ruta
          </button>
        </div>
      )}
      <div className="map-container mb40">
        <GoogleMap
          ref={mapRef}
          mapContainerStyle={containerStyle}
          center={mapState.center}
          zoom={14}
          onLoad={onLoad}
          onUnmount={onUnmount}
          options={Configuration}
        >
          {selected && <Marker position={selected} />}
          {selected2 && <Marker position={selected2} />}
          {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
        </GoogleMap>
      </div>
    </>
  ) : (
    <div className="spinner-position">
      <LoadSpiner />
    </div>
  );
};
//Componente hijo de mapa donde se indican las direcciones
const PlacesAutocomplete = forwardRef(
  (
    {
      setSelected,
      placeholder,
      countryZone,
      setStreet,
      setCity,
      initialStreet,
      setConfirmDirection,
      setRouteButton,
      //street,
    },
    ref
  ) => {
    const {
      ready,
      value,
      setValue,
      suggestions: { status, data },
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        componentRestrictions: {
          country: countryZone,
        },
      },
    });

    useImperativeHandle(ref, () => ({
      clearInput() {
        setValue('');
        clearSuggestions();
      },
    }));

    useEffect(() => {
      if (initialStreet) {
        if (value === '') {
          setValue(initialStreet, false);
        }
      }
    }, [initialStreet]);

    const handleSelect = async (address) => {
      setValue(address, false);
      setStreet(address);
      clearSuggestions();
      const results = await getGeocode({ address });

      const directions = results[0].address_components;
      directions.forEach((direction) => {
        if (direction.types[0] === 'administrative_area_level_3') {
          setCity(direction.long_name);
        }
      });

      const { lat, lng } = await getLatLng(results[0]);
      setSelected({ lat, lng });
    };

    const handleOnChange = (e) => {
      setValue(e.target.value);

      setConfirmDirection(false);
      setRouteButton(true);
    };

    const handleOnBlur = () => {
      // setValue(street);
    };

    return (
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          id={placeholder}
          value={value}
          onChange={(e) => handleOnChange(e)}
          onBlur={handleOnBlur}
          disabled={!ready}
          className="react-select clearable"
          placeholder="Ingrese una dirección..."
          type="search"
          required
        />
        {value.length > 1 && <BiX className="icon-close" onClick={() => setValue('')} />}
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' &&
              data.map(({ place_id, description }) => (
                <ComboboxOption key={place_id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    );
  }
);

const Depart = ({ id }) => {
  const [name, setName] = useState('');
  const maxChar = 30;
  const handleChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  return (
    <div className="mb25 h40">
      <div className="input-field col s12">
        <p className="label-react-select">{'(N° Dpto / N° Casa / Etc). '}</p>
        <input
          id={id}
          type="text"
          maxLength={maxChar}
          className="validate"
          value={name}
          onChange={handleChange}
          placeholder={'opcional...'}
        />
      </div>
    </div>
  );
};

export default MapGoogle;
