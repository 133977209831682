//Helper encargado de gestionar las llamadas al backend
export const apiPostPromise = (uri, headers, data, validMessage) => {
  return new Promise((resolve, reject) => {
    let requestOptions;
    if (data) {
      requestOptions = {
        method: 'POST',
        headers: headers,
        body: data,
        redirect: 'follow',
      };
    } else {
      requestOptions = {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
      };
    }
    fetch(uri, requestOptions)
      .then(async (response) => {
        try {
          if (response.status === 401) throw new Error(`UN AUTHORIZE SESSION EXPIRED ${response.status}`);
          const result = await response.text();
          const { mensaje } = JSON.parse(result);
          if (!response.ok) throw new Error(mensaje);
          console.log({ result, response: response.status })
          return { result, response: response.status };
        } catch (error) {
          reject(error);
        }
      })
      .then(({ result, response }) => {
        const { mensaje } = JSON.parse(result);
        if (mensaje?.toLowerCase() === validMessage) {
          return result;
        }

        reject({ result, response });
      })
      .then((result) => resolve(result))
      .catch((error) => {
        reject(error);
      });
  });
};
