import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoadSpiner } from "../LoadSpiner";
//Componentes de alta prioridad
import TypeService from "../../Components/Service/TypeService";
import WarrantyTicketFinder from "../../Components/Service/WarrantyTicketFinder";
import UbicationAndDestiny from "../../Components/Service/Flow/Ubication/UbicationAndDestiny";

export const AuthRoute = () => {
	//Se deja el lazy para que se cargue solo cuando se necesite
	const InsuredMaterial = lazy(() => import("../../Components/Service/InsuredMaterial"));
	const WithOutPlanRegister = lazy(() => import("../../Components/Service/WithOutPlanRegister"));
	const CoverageService = lazy(() => import("../../Components/Service/CoverageService"));
	const Service = lazy(() => import("../../Components/Service/Flow/Service"));
	const RequestType = lazy(() => import("../../Components/Service/Flow/Request/RequestType"));
	const ProgramedRequest = lazy(() => import("../../Components/Service/Flow/Request/ProgramedRequest"));
	const UploadPhoto = lazy(() => import("../../Components/Service/Flow/Upload/UploadPhoto"));
	const QuestionI = lazy(() => import("../../Components/Service/Flow/Quiz/QuestionI"));
	const Comments = lazy(() => import("../../Components/Service/Flow/Comments/Comments"));
	const ClientInformation = lazy(() => import("../../Components/Service/Flow/ClientInformation/ClientInformation"));
	const InmediateSummary = lazy(() => import("../../Components/Service/Flow/InmediateSummary/InmediateSummary"));
	return (
		<Suspense
			fallback={
				<div className="spinner-position">
					<LoadSpiner />
				</div>
			}
		>
			<Routes>
				<Route path="/TypeService" element={<TypeService />} />
				<Route path="/InsuredMaterial" element={<InsuredMaterial />} />
				<Route path="/Ticket" element={<WarrantyTicketFinder />} />
				<Route path="/WithOutPlanRegister" element={<WithOutPlanRegister />} />
				<Route path="/CoverageService" element={<CoverageService />} />
				{/* aqui comienza el flujo del script */}
				<Route path="/Service" element={<Service />} />
				<Route path="/RequestType" element={<RequestType />} />
				<Route path="/Ubication" element={<UbicationAndDestiny />} />
				<Route path="/ProgramedRequest" element={<ProgramedRequest />} />
				<Route path="/Uploadphoto" element={<UploadPhoto />} />
				<Route path="/Script" element={<QuestionI />} />
				<Route path="/Comments" element={<Comments />} />
				<Route path="/ClientInformation" element={<ClientInformation />} />
				<Route path="/Summary" element={<InmediateSummary />} />
				<Route path="*" element={<Navigate to="/view/HomePage" />} />
			</Routes>
		</Suspense>
	);
};
