import { sessionState } from "./../types/sessionState";

const initialState = {
  config: {
    sponsor: 0,
    flujo: 1,
    pais: 1,
    idDocument: 1,
  },
  pageStyle: {
    idPais: "1",
    nombreMarca: "GARANTIA",
    imagenlogo: "0.png",
    colorFondoSponsor: "#000000",
    colorTextoSponsor: "",
  },
  isLoading: false,
  msgError: null,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionState.uiSetError:
      return {
        ...state,
        msgError: action.payload,
      };
    case sessionState.uiRemoveError:
      return {
        ...state,
        msgError: null,
      };
    case sessionState.uiStartLoading:
      return {
        ...state,
        loading: true,
      };
    case sessionState.uiFinishLoading:
      return {
        ...state,
        loading: false,
      };
    case sessionState.uiSetPageConfig:
      return {
        ...state,
        config: {
          ...action.payload,
        },
      };
    case sessionState.uiSetPageStyle:
      return {
        ...state,
        pageStyle: {
          ...action.payload,
        },
      };
    case sessionState.uiResetPageStyle:
      return {
        ...state,
        pageStyle: {
          ...initialState.pageStyle,
        },
      };
    case sessionState.uiSetDocument:
      return {
        ...state,
        config: { 
          ...action.payload 
        },
      };
    default:
      return state;
  }
};
