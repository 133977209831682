import React from "react";
import { FaCarSide, FaHouseUser, FaPlane } from "react-icons/fa";
import { GiHastyGrave } from "react-icons/gi";
import { MdPets, MdOutlineCastForEducation } from "react-icons/md";
import { RiScales3Line, RiMedalLine, RiMedicineBottleLine, RiUserSearchFill } from "react-icons/ri";

const ItemIcon = ({ nombre, ruta }) => {
	const name = nombre.toString().toLowerCase();
	return (
		<>
			{name.includes("veh") && ruta.length === 0 && (
				<div style={{ marginLeft: "20px" }}>
					<FaCarSide size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}
			{name.includes("hog") && ruta.length === 0 && (
				<div>
					<FaHouseUser size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}
			{name.includes("leg") && ruta.length === 0 && (
				<div>
					<RiScales3Line size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}
			{name.includes("viaj") && ruta.length === 0 && (
				<div>
					<FaPlane size={45} className="icon__link material-icons icon__blue" style={{'margin-left': '42px'}}/>
				</div>
			)}
			{name.includes("gara") && ruta.length === 0 && (
				<div style={{ marginLeft: "20px" }}>
					<RiMedalLine size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}

			{name.includes("masco") && ruta.length === 0 && (
				<div style={{ marginLeft: "20px" }}>
					<MdPets size={45} className="icon__link material-icons icon__blue" style={{'margin-left': '20px'}}/>
				</div>
			)}
			{name.includes("medi") && ruta.length === 0 && (
				<div style={{ marginLeft: "20px" }}>
					<RiMedicineBottleLine size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}
			{name.includes("fune") && ruta.length === 0 && (
				<div style={{ marginLeft: "20px" }}>
					<GiHastyGrave size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}
			{name.includes("educa") && ruta.length === 0 && (
				<div style={{ marginLeft: "20px" }}>
					<MdOutlineCastForEducation size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}
			{name.includes("labor") && ruta.length === 0 && (
				<div style={{ marginLeft: "20px" }}>
					<RiUserSearchFill size={45} className="icon__link material-icons icon__blue" />
				</div>
			)}
			{ruta.length >= 10 && <div className="svg-icom-custom" dangerouslySetInnerHTML={{ __html: ruta.toString() }} />}
		</>
	);
};

export default ItemIcon;
