import React, { useState } from "react";
import { UseForm } from "../../../Hooks/UseForm";

const FormTicket = ({ handleSubmit: parentSubmit }) => {
	const { formState, handleInputChange } = UseForm({ ticket_input: "" });
	const [enableButton] = useState(true);
	//Llama al submit del padre y le pasa el formState
	const handleSubmit = (e) => {
		e.preventDefault();
		parentSubmit(formState.ticket_input);
	};
	//Renderizado del componente
	return (
		<form onSubmit={handleSubmit} className="form-ticket mb20" autoComplete="off">
			<div>
				<div id="Fields">
					<div className="mb25 h40">
						<div className="input-field col s12">
							<input
								id="ticket_input"
								name="ticket_input"
								type="number"
								className="validate"
								onChange={handleInputChange}
								value={formState.ticket_input}
								required
							/>
							<label htmlFor="ticket_input">Ingrese Nro Boleta</label>
						</div>
					</div>
				</div>
			</div>
			<button type="submit" className={enableButton ? "bttn bttn--blue" : "bttn bttn--gray"} disabled={!enableButton}>
				Buscar
			</button>
		</form>
	);
};

export default React.memo(FormTicket);
