import { getConfigData } from './config';
import { UseCookies } from './../Hooks/UseCookies';
import { UseEncryptor } from './../Hooks/UseEncryptor';
import { UseLocalStorage } from './../Hooks/UseLocalStorage';
import { sessionState } from './../types/sessionState';

const authToken = getConfigData(process.env.NODE_ENV).restoreSession;

//Crea la coockie encriptada con el token
export const setCoockie = (field, data) => {
  const dataEncrypt = UseEncryptor(data).encrypted(authToken);
  if (data?.length > 0 || data) removeCoockie(field);
  if (!getCoockie(field)) {
    UseCookies({
      field: field,
      data: dataEncrypt,
      path: '/',
      action: sessionState.SET_COOKIE,
    });
  }
};
//Crea el localstorage encriptado con el token
export const setStorage = (field, data) => {
  const dataEncrypt = UseEncryptor(data).encrypted(authToken);
  removeStorage(field);
  if (data?.length < 1) return;
  if (!getStorageItem(field)) {
    UseLocalStorage({
      field: field,
      data: dataEncrypt,
      action: sessionState.SET_ITEM,
    });
  }
};
//obtiene el valor del local storage desencriptado
export const getStorageItem = (field) => {
  return UseEncryptor(
    UseLocalStorage({
      field: field,
      action: sessionState.GET_ITEM,
    })
  ).desencrypted(authToken);
};
//obtiene el valor de la coockie desencriptado
export const getCoockie = (field) => {
  return UseEncryptor(
    UseCookies({
      field: field,
      action: sessionState.GET_COOKIE,
    })
  ).desencrypted(authToken);
};
//obtiene una lista de todos los valores almacenados en el storage
export const getStorageItems = () => {
  const config = UseCookies({ field: 'config', action: sessionState.GET_COOKIE });
  const fieldCoockie = getCoockie('fields');
  const coverage = getStorageItem('coverage');
  const activeService = getStorageItem('activeService');
  const product = getStorageItem('product');
  const activeCoverage = getStorageItem('activeCoverage');
  const activeCoverageService = getStorageItem('activeCoverageService');
  const ubicationState = getStorageItem('ubicationState');
  const ubication = getStorageItem('street');
  const point = getStorageItem('point');
  const activeScript = getStorageItem('script');
  const resumeDate = getStorageItem('resumeDate');
  const dateService = getCoockie('dateService');
  const images = getStorageItem('photos');
  const answer = getStorageItem('answer');
  const comment = getStorageItem('comment');
  const personalInfo = getStorageItem('personalInfo');

  let actualUrl;
  actualUrl = 'TypeService';
  actualUrl = activeCoverage ? 'TypeService' : actualUrl;
  actualUrl = activeCoverageService ? 'CoverageService' : actualUrl;
  actualUrl = ubication ? 'Ubication' : actualUrl;
  actualUrl = resumeDate ? 'RequestType' : actualUrl;
  actualUrl = images ? 'Uploadphoto' : actualUrl;
  actualUrl = answer ? 'Script' : actualUrl;
  actualUrl = comment ? 'Comments' : actualUrl;

  return {
    config,
    fieldCoockie,
    activeService,
    coverage,
    product,
    activeCoverage,
    activeCoverageService,
    ubicationState,
    ubication,
    point,
    activeScript,
    resumeDate,
    dateService,
    images,
    answer,
    comment,
    personalInfo,
    actualUrl,
  };
};
//elimina la coockie indicada
export const removeCoockie = (field) => {
  UseCookies({ field: field, path: '/', action: sessionState.REMOVE_COOKIE });
};
//elimina el localstorage indicado
export const removeStorage = (field) => {
  if (field === 'all') UseLocalStorage({ action: sessionState.CLEAR_ITEM });
  else UseLocalStorage({ field: field, action: sessionState.REMOVE_ITEM });
};
//elimina los valores entrys almacenados en el localstorage
export const removeStorageItemWithUrl = (url) => {
  let fieldArray;
  switch (url) {
    case 'TypeService':
      removeStorage('all');
      break;
    case 'InsuredMaterial':
      fieldArray =
        'coverage,activeCoverage,activeCoverageService,street,ubicationState,script,resumeDate,photos,answer,comment,personalInfo';
      break;
    case 'CoverageService':
      fieldArray =
        'activeCoverage,activeCoverageService,street,ubicationState,script,resumeDate,photos,answer,comment,personalInfo';
      break;
    case 'Service':
      fieldArray = 'street,ubicationState,script,resumeDate,photos,answer,comment,personalInfo';
      break;
    case 'Ubication':
      fieldArray = 'street,point,resumeDate,photos,answer,comment,personalInfo';
      break;
    case 'RequestType':
      fieldArray = 'resumeDate,photos,answer,comment,personalInfo';
      break;
    case 'Uploadphoto':
      fieldArray = 'photos,answer,comment';
      break;
    case 'Script':
      fieldArray = 'answer,comment';
      break;
    case 'Comments':
      fieldArray = 'comment';
      break;
    default:
      break;
  }
  if (!fieldArray) return;
  const fields = fieldArray.split(',');
  fields.forEach((field) => {
    UseLocalStorage({ field: field, action: sessionState.REMOVE_ITEM });
  });
};
