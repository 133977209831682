import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

export const PublicRoute = ({ isAuth, children }) => {
	return isAuth ? <Navigate to="/view/auth/TypeService" /> : children;
};

PublicRoute.propTypes = {
	isAuth: PropTypes.bool.isRequired,
	children: PropTypes.object.isRequired,
};
