import { sessionState } from "./../types/sessionState";

const initialState = {
	service: [],
	activeService: {
		sponsor: "",
		idTipoServicio: "",
		idContratoTipo: "",
		idFlujoTipoServicio: "",
		ruta: "",
	},
	fields: [],
	insuredMaterial: [],
};

export const planReducer = (state = initialState, action) => {
	switch (action.type) {
		case sessionState.GET_SERVICES:
			return {
				...state,
				service: action.payload.data,
			};
		case sessionState.ACTIVE_SERVICE:
			return {
				...state,
				activeService: action.payload.activeService,
			};
		case sessionState.GET_FIELDS:
			return {
				...state,
				fields: action.payload.data,
			};
		case sessionState.SET_ASSISTANCE_ABSENT:
			return {
				...state,
				assistenceCoverage: action.payload.assistenceCoverage,
			};
		case sessionState.SET_WARRANTY_ABSENT:
			return {
				...state,
				warrantyCoverage: action.payload.warrantyCoverage,
			};
		case sessionState.SET_INSURED_MATERIAL:
			return {
				...state,
				insuredMaterial: action.payload.insuredMaterialSelect,
			};
		case sessionState.RESET_PLAN:
			return {
				...initialState,
			};
		default:
			return state;
	}
};
