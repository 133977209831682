import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { UseCookies } from "../Hooks/UseCookies";
import { getParams, redirectSponsor } from "../helpers/tracking";
import { startAddNewTracking, startLogout, startSyncStyle } from "../actions/auth";
import { uiSetPageConfig } from "../actions/ui";
import { sessionState } from "../types/sessionState";
//Rutas cuando esta logueado
import { PrivateRoute } from "./auth/PrivateRoute";
import { AuthRoute } from "./auth/AuthRoute";
//Rutas cuando no esta logueado
import { PublicRoute } from "./login/PublicRoute";
import { LoginRoute } from "./login/loginRoute";
import { LoadSpiner } from './LoadSpiner';

export const AppRouter = () => {

	if(!window.location.pathname.includes("/view/")){
	redirectSponsor();
	return <>
		<div className="spinner-position">
			<LoadSpiner />
		</div>
	</>
	}

	const dispatch = useDispatch();
	//se inicializan variables a utilizar en el componente
	const { loading, pageStyle } = useSelector((state) => state.ui);
	const { session } = useSelector((state) => state.auth);
	const url = window.location.pathname;
	const getToken = () => {
		return UseCookies({ field: "accessToken", action: sessionState.GET_COOKIE });
	};
	const getStyle = () => {
		return UseCookies({ field: "style", action: sessionState.GET_COOKIE }) || pageStyle;
	};
	const isData = getToken();
	const [isLoggedIn, setIsLoggedIn] = useState(isData ? true : false);
	//Aqui cambio la variable de color global.
	document.documentElement.style.setProperty("--color-primary-theme-default", getStyle().colorFondoSponsor);
	document.title = getStyle().nombreMarca;
	//Aqui obtengo los parametros de la url solo para puntos validos del sitio
	const setQueryParams = () => {
		const queryString = getParams();
		dispatch(uiSetPageConfig(queryString));
		dispatch(startSyncStyle(queryString.sponsor));
	};
	//Aqui se inicializa el seguimiento y lo ira validando cada vez que cambie la url
	const startTrackingMetrics = () => {
			!session  && dispatch(startAddNewTracking());			
	};

	//efecto que va a ejecutar solo una vez al iniciar la aplicacion o refrescar la pagina
	useEffect(() => {
		if (url.includes("/TypeService") || url.includes("/login") || url.includes("/SMS")) {
			setQueryParams();
			startTrackingMetrics();
		}
	}, []);
	//efecto que va a ejecutar cada vez que se dispare un dispatch para validar si esta logueado
	useEffect(() => {
		startTrackingMetrics();
		setIsLoggedIn(isData ? true : false);
		if (!isData && url.includes("/auth/")) {
			dispatch(startLogout());
		}
	}, [dispatch, isData, loading]);
	//Renderizado de las rutas
	return (
		<BrowserRouter>
			<Routes>
				
				<Route
					path="/view/*"
					element={
						<PublicRoute isAuth={isLoggedIn}>
							<LoginRoute />
						</PublicRoute>
					}
				/>
				<Route
					path="/view/auth/*"
					element={
						<PrivateRoute isAuth={isLoggedIn}>
							<AuthRoute />
						</PrivateRoute>
					}
				/>
				<Route path="*" element={<Navigate to="/view/HomePage" />} />
			</Routes>
		</BrowserRouter>
	);
};
