export const sessionState = {
  LOGIN: '[Auth] LOGIN',
  LOGOUT: '[Auth] LOGOUT',
  RESTORE_SESSION: '[Auth] RESTORE SESSION',
  sessionGUID: '[Auth] SESSION GUID',

  PRE_ASIGN_LOGIN: '[PRE_ASING_L] USER DATA',
  PRE_ASIGN_REGISTER: '[PRE_ASING_R] USER DATA',

  uiSetError: '[UI] SET_ERROR',
  uiRemoveError: '[UI] REMOVE_ERROR',
  uiStartLoading: '[UI] START_LOADING',
  uiFinishLoading: '[UI] FINISH_LOADING',
  uiSetPageConfig: '[UI] SET_PAGE_CONFIG',
  uiSetPageStyle: '[UI] SET_PAGE_STYLE',
  uiResetPageStyle: '[UI] RESET_PAGE_STYLE',
  uiSetDocument: '[UI] SET_DOCUMENT',

  sendSMS: '[Auth] sendSMS',
  setTypeDocument: '[Auth] setTypeDocument',
  

  SET_COOKIE: '[COOKIE] SET',
  GET_COOKIE: '[COOKIE] GET',
  REMOVE_COOKIE: '[COOKIE] REMOVE',
  GET_ALL_COOKIE: '[COOKIE] GET_ALL',

  RESET_PLAN: '[PLAN] RESET',

  SET_ITEM: '[STORAGE] SET',
  GET_ITEM: '[STORAGE] GET',
  REMOVE_ITEM: '[STORAGE] REMOVE',
  CLEAR_ITEM: '[STORAGE] CLEAR',
  GET_ALL_ITEM: '[STORAGE] GET_ALL',

  GET_SERVICES: '[PLAN] GET SERVICES',
  ACTIVE_SERVICE: '[PLAN] ACTIVE SERVICE',
  GET_FIELDS: '[PLAN] GET FIELDS',
  SET_ASSISTANCE_ABSENT: '[PLAN] CREATE ASSISTANCE ABSENT',
  SET_WARRANTY_ABSENT: '[PLAN] CREATE WARRANTY ABSENT',

  SET_INSURED_MATERIAL: '[PLAN] SET INSURED MATERIAL',

  SET_ASSISTANCE: '[PLAN] CREATE ASSISTANCE',
  SET_WARRANTY: '[PLAN] CREATE WARRANTY',

  SET_PRECASE: '[PLAN] SET PRECASE',
  CLEAR_PRECASE: '[PLAN] CLEAR PRECASE',
  SET_ACTIVE_COVERAGE: '[PLAN] SET ACTIVE COVERAGE',
  SET_ACTIVE_SERVICE_COVERAGE: '[PLAN] SET ACTIVE SERVICE COVERAGE',
  SET_UBICATION: '[PLAN] SET UBICATION FLOW',
  SET_SCRIPT: '[PLAN] SET SCRIPT',

  SET_PERSONAL_INFO: '[PLAN] SET INFO',
  GET_PERSONAL_INFO: '[PLAN] GET INFO',
};
