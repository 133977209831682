export const origen = {
  facebook: 1,
  google: 2,
  manual: 3,
  sms: 4,
  qr: 5,
  extranjero:9
};

export const tipoDocumento = {
  rut: 1,
};

export const userLogin = {
  rut: '',
  clave: '',
  idOrigin: '',
  token: '',
  guid: '',
};

export const userRegistrer = {
  idDeAplicacion: '',
  nombres: '',
  apellidos: '',
  email: '',
  nroDocumento: '',
  telefono: '',
  idOrigen: 0,
  idTipoDocumento: 0,
  clave: '',
  fotoPerfil: '',
  guid: '',
};

export const traking = {
  guid: '',
  flag: '',
  request: '',
  ip: '',
  browser: '',
};

export const icon = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
  question: 'question',
};

export const personalInfo = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  update: false,
};
