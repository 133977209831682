import React from "react";
import { Swal } from 'sweetalert2';

const ItemTicket = ({ ticket, ruta, handleTicketSelect }) => {
	//Se crea un array de objeto para mostrar las boletas
	const materiaAseguradaBase = ticket?.map((item) => {
		const subItem = item.htmlMateriaAsegurada.split(",");
		if (subItem.length >= 7) {
			const materia = {
				fechaCompra: subItem[0],
				nroBoleta: subItem[1],
				marca: subItem[2],
				producto: subItem[3],
				fechaInicioVigencia: subItem[4],
				fechaFinVigencia: subItem[5],
				nombreSubClaseCategoria: subItem[6],
				otro: subItem[7],
			};
			return { htmlMateriaAsegurada: materia, idPlanMateria: item.idPlanMateria };
		}
		const materia = {
			fechaCompra: "",
			nroBoleta: "",
			marca: "",
			producto: "",
			fechaInicioVigencia: "",
			fechaFinVigencia: "",
			nombreSubClaseCategoria: "",
			otro: "",
		};
		return { htmlMateriaAsegurada: materia, idPlanMateria: item.idPlanMateria };
	});
	//Filtra los ticket que no tienen marca
	let materiaAsegurada = materiaAseguradaBase?.filter((item) => item.htmlMateriaAsegurada.marca !== "");
	//Llama al metodo handleTicketSelect para seleccionar el ticket este vienen del padre
	const handleSelectProduct = (idPlanMateria, htmlMateriaAsegurada, otro) => {
		if(otro === '0') {
			Swal.fire('Busqueda por boleta', 'el producto no cuenta con vigencia de planes', 'info')	
			return
		}
		handleTicketSelect(idPlanMateria, htmlMateriaAsegurada);
	};	
	//Renderizado del componente
	return (
		<>
			{materiaAsegurada?.length > 0 &&
				materiaAsegurada.map(({ htmlMateriaAsegurada, idPlanMateria }) => (
					<div key={idPlanMateria} onClick={() => handleSelectProduct(idPlanMateria, htmlMateriaAsegurada,htmlMateriaAsegurada.otro)} className="option">
						<div className="option__box-title">
							{ruta.length >= 10 && (
								<div
									className="header-quaternary__img material-icons icon__blue svg-icom-custom-coverage"
									dangerouslySetInnerHTML={{ __html: ruta.toString() }}
								/>
							)}
							<div className="ticket-description">
								<p className="option__text-ticket">
									Boleta: <span className="option__value-ticket">{htmlMateriaAsegurada.nroBoleta}</span>
								</p>
								<p className="option__text-ticket">
									Fecha de Compra: <span className="option__value-ticket">{htmlMateriaAsegurada.fechaCompra}</span>
								</p>
								<p className="option__text-ticket">
									Producto: <span className="option__value-ticket">{htmlMateriaAsegurada.producto}</span>
								</p>
								<p className="option__text-ticket">
									Marca: <span className="option__value-ticket">{htmlMateriaAsegurada.marca}</span>
								</p>
								<p className="option__text-ticket">
									Inicio Vigencia: <span className="option__value-ticket">{htmlMateriaAsegurada.fechaInicioVigencia}</span>
								</p>
								<p className="option__text-ticket">
									Termino Vigencia: <span className="option__value-ticket">{htmlMateriaAsegurada.fechaFinVigencia}</span>
								</p>
							</div>
						</div>
					</div>
				))}
		</>
	);
};

export default React.memo(ItemTicket);
