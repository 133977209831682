import { sessionState } from './../types/sessionState';
import { personalInfo, userLogin, userRegistrer } from './../types/user';

export const userLoginReducer = (state = userLogin, action) => {
  switch (action.type) {
    case sessionState.PRE_ASIGN_LOGIN:
      return {
        ...state,
        ...action.payload.userLogin,
      };
    case sessionState.LOGIN:
      return {
        ...state,
      };
    case sessionState.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = userRegistrer, action) => {
  switch (action.type) {
    case sessionState.PRE_ASIGN_REGISTER:
      return {
        ...state,
        ...action.payload.userRegistrer,
      };
    case sessionState.LOGIN:
      return {
        ...state,
      };
    case sessionState.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userSMSReducer = (state = {}, action) => {
  switch (action.type) {
    case sessionState.sendSMS:
      return {
        ...state,
        smsCode: action.payload.smsCode,
      };
    case sessionState.LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userPersonalInfoReducer = (state = personalInfo, action) => {
  switch (action.type) {
    case sessionState.SET_PERSONAL_INFO:
      return {
        ...state,
        personalInfo: action.payload.personState,
      };
    case sessionState.GET_PERSONAL_INFO:
      return {
        ...state,
      };
    case sessionState.LOGOUT:
      return {};
    default:
      return state;
  }
};
