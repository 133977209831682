import React from "react";
import { useDispatch } from "react-redux";
import { startUpdateTracking } from "../actions/auth";
import { removeStorageItemWithUrl } from "../helpers/localState";
//Componente encargado de mostrarse cuando ocurre un error en la pagina
const ErrorPage = ({ error }) => {
	const dispatch = useDispatch();

	//cuando pasan 5 segundos redirige al usuario a la pantalla de inicio de seleccion de servicio si no esta logueado a login
	setTimeout(() => {
		console.warn({error})
		dispatch(startUpdateTracking({ request: `error: ${error.message}` }));
		removeStorageItemWithUrl("TypeService");
		window.location.href = "/view/auth/TypeService";
	}, 5000);
	//Renderizado del componente
	return (
		<div className="contenedor-maxw">
			<section className="big-box-white bor-rad20">
				<h1 className="title__tertiary-title mb20">Error</h1>
				<div className="box-center mb40">
					<h3 className="title__form-subtitle mar-0">¡El recurso o el sitio no es valido!</h3>
				</div>
				<div className="box-center mb40">{/* <h3 className="title__form-subtitle mar-0">{error.message}</h3> */}</div>
				<div className="box-center mb40">
					<h3 className="title__form-subtitle mar-0">Sera redirigido al inicio en 5 segundos...</h3>
				</div>
			</section>
		</div>
	);
};

export default ErrorPage;
