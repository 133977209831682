import CryptoJS from "crypto-js";

export const UseEncryptor = (content) => {
	let ecryptedData = null;
	const desencrypted = (key = "") => {
		let decryptedData = null;
		let bytes = null;
		try {
			ecryptedData = !ecryptedData ? content : ecryptedData;
			if (ecryptedData) bytes = CryptoJS.AES.decrypt(ecryptedData, key);
			if (bytes) decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		} catch (error) {}
		return decryptedData;
	};
	const encrypted = (key = "") => {
		try {
			ecryptedData = CryptoJS.AES.encrypt(JSON.stringify(content), key).toString();
		} catch (error) {}
		return ecryptedData;
	};
	return {
		desencrypted,
		encrypted,
	};
};
